import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { CurrenciesEnum } from '@shared-enum/currencies.enum';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { paymentFlowSelectorOptions } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector-options/payment-flow-selector-options.options';
import { PaymentFlowSelectorProps } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.interface';

export const usePaymentFlowSelectorOptions = ({
    accounts,
    setSelectedAccount,
}: Pick<PaymentFlowSelectorProps, 'accounts' | 'setSelectedAccount'>) => {
    const {
        values: { selectedAccount: selectedAccountId },
    } = useFormikContext();
    const selectedAccount = useMemo(
        () => accounts?.find(({ accountId }) => selectedAccountId === accountId),
        [accounts, selectedAccountId]
    );

    useEffect(() => {
        setSelectedAccount(selectedAccount ?? accounts[0]);
    }, [selectedAccount]);

    return useMemo(
        () =>
            paymentFlowSelectorOptions
                .filter(({ availableCurrencies }) =>
                    availableCurrencies.includes(selectedAccount?.currency?.code as CurrenciesEnum)
                )
                .map(followSelectorOption => ({
                    ...followSelectorOption,
                    tooltipText: (
                        <Translate langKey={followSelectorOption.tooltipText} customRender={linkMarkdownTag} />
                    ),
                })),
        [selectedAccount]
    );
};
