import React, { useCallback } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { WebPreferredLoginMethod } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { LoginMethodSelectorInterface } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.interface';
import { LoginMethodSelectorWrapper } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.styles';

export const LoginMethodSelector = ({
    webAvailableLoginMethods,
    setLoginSelectorVisible,
    onLoginWithAnotherMethod,
    setManuallyPreferredLoginMethod,
}: LoginMethodSelectorInterface) => {
    const onMethodButtonClick = useCallback((method: WebPreferredLoginMethod) => {
        setLoginSelectorVisible(false);
        onLoginWithAnotherMethod(method);
        setManuallyPreferredLoginMethod(method);
    }, []);

    const onBackButtonClick = useCallback(() => {
        setLoginSelectorVisible(false);
        setManuallyPreferredLoginMethod(null);
    }, []);

    return (
        <LoginMethodSelectorWrapper>
            <Button
                onClick={onBackButtonClick}
                type={ButtonTypeEnum.GHOST}
                size={ButtonSizeEnum.L}
                title={LocalizationEnum.CommonBack}
                iconBefore={ArrowLeft}
            />
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.Sms)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.Sms)}
                    title={LocalizationEnum.CommonSms}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                />
            )}
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.GoogleAuthenticator)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.GoogleAuthenticator)}
                    title={LocalizationEnum.CommonGoogleAuthenticator}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                />
            )}
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.PushNotification)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.PushNotification)}
                    title={LocalizationEnum.ThroughSendsApplication}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                />
            )}
        </LoginMethodSelectorWrapper>
    );
};
