import * as Sentry from '@sentry/react';
import { applySnapshot, flow, getParent, getSnapshot, Instance } from 'mobx-state-tree';

import { GetCurrentUserProfile, Query, User, UserProfile } from '@shared-graphql';
import { UserProfileType } from '@shared-model/user-model/user-model-private/user-profile/user-profile.type';
import { isExist } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { UserModel } from '@model/user-model/user-model';
import { initialUserProfileModel } from '@model/user-model/user-model-initials/user-profile-model.initial';
import { UserModelType } from '@model/user-model/user-model.type';

const queryUserProfile = () =>
    apolloClient
        .query<Pick<Query, 'getCurrentUser'>>({ query: GetCurrentUserProfile })
        .then(result => (result.data.getCurrentUser as User).profile);

export const UserProfileTypeInstance = UserProfileType.actions(self => ({
    applyUserProfile: (profile: UserProfile) => {
        const userModel = getParent(self) as Instance<typeof UserModelType>;
        userModel.privateStatus.setHasProfile(true);

        const currentSnapshot = getSnapshot(self);
        applySnapshot(self, {
            ...currentSnapshot,
            ...profile,
        });
    },
})).actions(self => ({
    loadUserProfile: flow(function* loadUserProfile() {
        const userModel = getParent(getParent(self)) as Instance<typeof UserModel>;

        if (userModel.loading.isProfileLoading || self.name !== initialUserProfileModel.name) {
            return;
        }

        userModel.setUserProfileLoading(true);

        try {
            const profile = yield queryUserProfile();

            if (isExist(profile)) {
                if (profile.dateBirthday === 0) {
                    profile.dateBirthday = null;
                }
                self.applyUserProfile(profile);
            }
        } catch (error) {
            Sentry.captureException(error);
            throw new Error(error);
        }

        userModel.setUserProfileLoading(false);
    }),
}));
