export enum PageEnum {
    Home = '/',
    BusinessHome = '/business',
    Acquiring = '/acquiring',
    Payouts = '/payouts',
    Prices = '/pricing',
    ContactUs = '/contact-us',
    AboutUs = '/about-us',
    Careers = '/careers',
    MobileApp = '/mobile-app',

    VacancyContentAndSMMManager = '/vacancy/content-and-smm-manager',
    VacancySeniorFinanceManager = '/vacancy/senior-finance-manager',
    VacancyCopywriter = '/vacancy/copywriter',
    VacancyNodeJsDeveloper = '/vacancy/node-js-developer',
    VacancySeniorPHPDeveloper = '/vacancy/senior-php-developer',

    TermsAndConditions = '/terms-and-conditions',
    Complaints = '/complaints',
    Smartflow = '/smartflow',
    PrivacyPolicy = '/privacy-policy',
    OnlineAccount = '/online-account',
    HRDataPrivacyNotice = '/hr-data-privacy-notice',
    AcquiringServicesAgreement = '/acquiring-services-agreement',
    CookiePolicy = '/cookie-policy',
    ModernSlaveryStatement = '/modern-slavery-statement',
    ValueMissionStatement = '/value-mission-statement',
    LegalDocuments = '/legal-documents',

    MobileAppComplaints = '/mobile-app-complaints',
    MobileAppTermsAndConditions = '/mobile-app-terms-and-conditions',
    MobileAppPrivacyPolicy = '/mobile-app-privacy-policy',
    MobileAppHRDataPrivacyNotice = '/mobile-app-hr-data-privacy-notice',
    MobileAppAcquiringServicesAgreement = '/mobile-app-acquiring-services-agreement',
    MobileAppModernSlaveryStatement = '/mobile-app-modern-slavery-statement',
    MobileAppValueMissionStatement = '/mobile-app-value-mission-statement',

    OnBoarding = '/onboarding',
    Login = '/login',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password/:code',
    AuthPartners = '/auth',
    AuthPartnersSuccess = '/auth/success',
    Verification = '/verification',

    Dashboard = '/dashboard',
    CommissionProfile = '/commission-profile',
    CabinetAcquiring = '/cabinet-acquiring',
    PermittedCountries = '/permitted-countries',
    Settings = '/settings',
    Notification = '/notifications',

    RegistrationPassword = '/registration/password',
    RegistrationAdditionalFields = '/registration/additional-fields',
    RegistrationPhone = '/registration/phone',
    RegistrationOtp = '/registration/otp',
}
