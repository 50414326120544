import { useFormikContext } from 'formik';
import React from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { ButtonsWrapper } from '@component/modal/modals/payment/payment.modal-style';
import { IsPermissions } from '@page/workspace/address-book/address-book-header/address-book-header';

interface PaymentUpdateStepsButtonsProps {
    onPaymentSign: OnEventEmptyType;
    onBack: OnEventEmptyType;
    isPaymentSigned: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    isSignMode: boolean;
    ibanPaymentFlow: boolean;
}

export const PaymentUpdateStepsButtons = ({
    onPaymentSign,
    onBack,
    isPaymentSigned,
    isLoading,
    isDisabled,
    isSignMode,
    ibanPaymentFlow,
}: PaymentUpdateStepsButtonsProps) => {
    const isRoleB = IsPermissions('signPayments');
    const { handleSubmit } = useFormikContext();

    const onPayNowButtonClick = () => {
        onPaymentSign();
        if (!isSignMode || ibanPaymentFlow) {
            handleSubmit();
        }
    };

    return (
        <ButtonsWrapper>
            {!isPaymentSigned && (
                <Button
                    onClick={onBack}
                    type={ButtonTypeEnum.GHOST}
                    size={ButtonSizeEnum.L}
                    title={LocalizationEnum.CommonBack}
                    iconBefore={ArrowLeft}
                />
            )}
            {!isRoleB && (
                <Tooltip
                    dataFor={TooltipDataForEnum.PaymentSign}
                    content={LocalizationEnum.YouDoNotHavePermissionPleaseContactOurSupportTeam}
                    isShow={isRoleB}
                >
                    <Button
                        type={ButtonTypeEnum.COLOR}
                        size={ButtonSizeEnum.L}
                        title={LocalizationEnum.HomeServiceItemTitle3}
                        onClick={onPayNowButtonClick}
                        isDisabled={isDisabled || isLoading || isRoleB}
                        isLoading={isLoading}
                    />
                </Tooltip>
            )}
        </ButtonsWrapper>
    );
};
