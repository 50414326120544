import ReactGA from 'react-ga4';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Link } from '@shared-atom/link/link';
import { UserType } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { GAEventActionsEnum, GAEventCategoryEnum } from '@enum/ga-event.enum';
import { PageEnum } from '@enum/page.enum';
import { ButtonsWrapper } from '@page/main-pages/main-page-common/header/header-right-side/header-right-side-btn/header-right-side-login-btn.styles';

export const HeaderRightSideBtn = ({
    userId,
    isBusinessPage,
    btnSize = ButtonSizeEnum.M,
    isMobile = false,
}: {
    userId: string | null;
    isBusinessPage: boolean;
    btnSize?: ButtonSizeEnum;
    isMobile?: boolean;
}) => {
    const navigate = useRouterNavigate();

    if (isTrue(userId)) {
        return (
            <Link route={PageEnum.Dashboard}>
                <Button
                    size={btnSize}
                    type={isBusinessPage ? ButtonTypeEnum.WHITE_STROKE : ButtonTypeEnum.BLACK_STROKE}
                    title={LocalizationEnum.RegistrationBusinessSuccessBtn}
                    onClick={noop}
                />
            </Link>
        );
    }
    return (
        <ButtonsWrapper>
            <Button
                size={btnSize}
                type={isBusinessPage ? ButtonTypeEnum.WHITE_STROKE : ButtonTypeEnum.BLACK_STROKE}
                title={LocalizationEnum.CommonLogin}
                onClick={() => navigate(PageEnum.Login)}
            />
            {!isMobile && (
                <Button
                    size={btnSize}
                    type={ButtonTypeEnum.COLOR}
                    title={LocalizationEnum.CommonCreateAnAccount}
                    onClick={() => {
                        ReactGA.event({
                            category: GAEventCategoryEnum.User,
                            action: GAEventActionsEnum.OpenAccountClickBtn,
                        });
                        navigate(PageEnum.OnBoarding, {
                            activeType: isBusinessPage ? UserType.Legal : UserType.Private,
                        });
                    }}
                />
            )}
        </ButtonsWrapper>
    );
};
