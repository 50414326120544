import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useRef, useState } from 'react';
import ReactSelect, { ValueType, SelectComponentsConfig, components } from 'react-select';
import { ElRef } from 'react-select/base';
import { StateManager } from 'react-select/src/stateManager';

import { FieldError } from '../../../components/field-error/field-error';
import { useLocalizationText } from '../../../hooks/localization/use-localization-text.hook';
import { useBoundingSize } from '../../../hooks/utils/use-bounding-size.hook';
import { LocalizationEnum } from '../../../locale/localization.enum';
import { isExist, isString } from '../../../utils/is-data';
import { noop } from '../../../utils/noop';
import { Translate } from '../../translate/translate';
import { Label } from '../input/input.styles';
import { CustomSelectMenuList } from './custom-select-menu-list/custom-select-menu-list';
import { customSelectRowRender } from './custom-select.initial';
import { BaseOptionInterface, CustomSelectProps } from './custom-select.props';
import {
    containerStyle,
    controlStyle,
    inputStyle,
    placeholderStyle,
    selectMenuStyle,
    SelectWrapper,
    ErrorWrapper,
    Wrapper,
    singleValueStyle,
    valueContainerStyle,
    indicatorSeparatorStyle,
    indicatorsContainerStyle,
    noOptionsMessageStyle,
} from './custom-select.styles';

export const CustomSelect = observer(
    <T extends BaseOptionInterface>({
        name,
        label,
        placeholder,
        options = [],
        rowHeight = 48,
        rowCountView = 3,
        menuHeight,
        menuWidth,
        isUseVal,
        isDisabled = false,
        rowRender = customSelectRowRender,
        onChange = noop,
        isMobile = false,
        valueRender = props => {
            const item = props.getValue()[0] ?? {};

            return <Translate langKey={item.label} />;
        },
        forCountrySelect = false,
        ...selectProps
    }: CustomSelectProps<T>) => {
        const [field, { error }, { setValue, setError }] = useField<ValueType<T, boolean> | undefined>(name);

        const [isMenuOpened, setMenuOpened] = useState(false);
        const [filterValue, setFilterValue] = useState('');
        const selectRef = useRef() as RefObject<StateManager<T, boolean>>;
        const controlSize = useBoundingSize(selectRef.current?.select.controlRef);
        const selectOptions = isString(filterValue)
            ? options.filter(option =>
                  option[isUseVal !== undefined ? 'value' : 'label'].toLowerCase().includes(filterValue)
              )
            : options;
        const rowCount = selectOptions.length;
        const hasError = isExist(error);

        const menuListHeight = menuHeight ?? rowHeight * (rowCountView > rowCount ? rowCount : rowCountView);

        const handleChange = (selectValue: any) => {
            setValue(selectValue?.value);
            onChange();
        };

        const toggleFocus = (isOpen: boolean) => () => {
            setMenuOpened(isOpen);
            if (hasError) {
                setError(undefined);
            }
        };
        const handleInputChange = (value: string) => setFilterValue(value.toLowerCase());

        const selectComponents = {
            SingleValue: valueRender ?? components.SingleValue,
            MenuList:
                rowCount > 0
                    ? CustomSelectMenuList<T>({
                          menuHeight: menuListHeight,
                          rowHeight,
                          rowCount,
                          rowRender,
                          menuWidth: menuWidth ?? controlSize?.width ?? 0,
                      })
                    : components.MenuList,
        } as unknown as SelectComponentsConfig<T, boolean>;

        const optionValue = selectOptions.find(({ value }) => value === (field.value as any as string));
        const shouldRenderLabel = isString(label);
        const normalizedPlaceholder = useLocalizationText(placeholder ?? '');

        return (
            <Wrapper>
                {shouldRenderLabel && (
                    <Label hasError={hasError}>
                        <Translate langKey={label as LocalizationEnum} />
                    </Label>
                )}
                <SelectWrapper>
                    <ReactSelect
                        {...selectProps}
                        isDisabled={isDisabled}
                        ref={selectRef as ElRef}
                        placeholder={normalizedPlaceholder}
                        value={optionValue ?? null}
                        options={selectOptions}
                        components={selectComponents as any}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onMenuOpen={toggleFocus(true)}
                        onMenuClose={toggleFocus(false)}
                        openMenuOnFocus
                        styles={{
                            container: containerStyle,
                            placeholder: placeholderStyle(forCountrySelect, isMobile),
                            valueContainer: valueContainerStyle(forCountrySelect, isMobile),
                            singleValue: singleValueStyle(),
                            input: inputStyle(forCountrySelect),
                            menu: selectMenuStyle(),
                            indicatorSeparator: indicatorSeparatorStyle(),
                            indicatorsContainer: indicatorsContainerStyle(isDisabled),
                            control: controlStyle(isMenuOpened, hasError, isMobile),
                            noOptionsMessage: noOptionsMessageStyle,
                        }}
                    />
                </SelectWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </Wrapper>
        );
    }
);
