import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { ConfirmSignPayment, CreatePayment, DefaultAnswer, Mutation, Payment, StartSignPayment } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { emit } from '@shared-util/event-emitter';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';

export const usePaymentUpdate = (
    existPaymentId: string | null,
    setPaymentError: Dispatch<SetStateAction<boolean>>,
    isSignMode: boolean
) => {
    const [step, setStep] = useState(isSignMode ? PaymentUpdateStepsEnum.Otp : PaymentUpdateStepsEnum.ConfirmPayment);
    const [isPayNowActivated, setPayNowActivated] = useState(false);

    const [signPaymentError, setSignPaymentError] = useState('');
    const OtpCodeIncorrectError = useLocalizationText(LocalizationEnum.OtpCodeIncorrectError);

    const [createPayment, { data: createPaymentData, loading: isLoadingPayment }] = useMutation<
        Pick<Mutation, 'createPayment'>
    >(CreatePayment, {
        onError: error => {
            if (isTrue(error)) {
                setPaymentError(true);
            }
        },
        onCompleted: result => {
            if (result === null) {
                setPaymentError(true);
            }
        },
    });
    const [startSignPayment, { data: startSignPaymentData, loading: isStartSignPaymentLoading }] = useMutation<
        Pick<Mutation, 'startSignPayment'>
    >(StartSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setPaymentError(true);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse((result?.startSignPayment as DefaultAnswer)?.status)) {
                setPaymentError(true);
            }
        },
    });
    const [confirmSignPayment, { data: confirmSignPaymentData, loading: isConfirmSignPaymentLoading }] = useMutation<
        Pick<Mutation, 'confirmSignPayment'>
    >(ConfirmSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse(result?.confirmSignPayment?.status)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
    });

    const paymentId = (createPaymentData?.createPayment as Payment)?.id ?? existPaymentId;
    const isPaymentSigned = (confirmSignPaymentData?.confirmSignPayment as DefaultAnswer)?.status ?? false;

    useEffect(() => {
        if (isExist(createPaymentData)) {
            if (isPayNowActivated) {
                startSignPayment({ variables: { paymentId } });
            } else {
                setStep(PaymentUpdateStepsEnum.PaymentCreated);
            }
        }
    }, [createPaymentData, paymentId]);

    useEffect(() => {
        if ((startSignPaymentData?.startSignPayment as DefaultAnswer)?.status) {
            setStep(PaymentUpdateStepsEnum.Otp);
        }
    }, [startSignPaymentData]);

    useEffect(() => void (isPaymentSigned && setStep(PaymentUpdateStepsEnum.PaymentSigned)), [isPaymentSigned]);

    const onPaymentSign = () => setPayNowActivated(true);
    const onSendOtpCode = () => startSignPayment({ variables: { paymentId } });

    const onOtpSubmit = (otp: string) => {
        if (!isExist(paymentId)) {
            return;
        }

        confirmSignPayment({
            variables: {
                paymentId,
                otp,
            },
        })
            .catch(() => setPaymentError(true))
            .finally(() => {
                emit(EventEmitterEnum.TransactionsUpdate);
                emit(EventEmitterEnum.AccountsUpdate);
            });
    };

    useEffect(() => {
        if (isSignMode) {
            onSendOtpCode();
        }
    }, [isSignMode]);

    return {
        paymentUpdateStep: step,
        signPaymentError,
        setSignPaymentError,
        createPayment,
        isLoadingPayment: isLoadingPayment || isStartSignPaymentLoading || isConfirmSignPaymentLoading,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    };
};
