import { useStore } from '@store/store-context.hook';

export const useUserActionsSelector = () => {
    const {
        setUserData,
        setUserType,
        setUserAddress,
        setUserService,
        setUserDeclaration,
        setUserDocuments,
        setUserHasDocuments,
        setUserEmail,
        setUserAccept,
        setUserPhone,
        setUserWebPreferredLoginMethod,
        setUserWebAvailableLoginMethods,
        setUserCompanyRole,
        setUserAcceptAgreement,
        clearUserData,
        logoutUser,
        loadCurrentUser,
        setUserActivationStatus,
        getUserActivationStatus,
        user: {
            profile: { loadUserProfile },
        },
    } = useStore().user;

    return {
        setUserData,
        setUserType,
        loadUserProfile,
        setUserAddress,
        setUserService,
        setUserDeclaration,
        setUserDocuments,
        setUserHasDocuments,
        setUserAccept,
        setUserEmail,
        setUserPhone,
        setUserWebPreferredLoginMethod,
        setUserWebAvailableLoginMethods,
        setUserCompanyRole,
        setUserAcceptAgreement,
        clearUserData,
        loadCurrentUser,
        logoutUser,
        setUserActivationStatus,
        getUserActivationStatus,
    };
};
