import { observer } from 'mobx-react-lite';
import { SetStateAction, useEffect, useState } from 'react';

import { WebPreferredLoginMethod } from '@shared-graphql';
import { useLoginSteps } from '@shared-hook/login-steps/login-steps.hook';
import { isTrue } from '@shared-util/is-data';

import { GoogleAuthLoginStep } from '@page/auth-pages/login/login-steps/google-auth-login-step/google-auth-login-step';
import { LoginMethodSelector } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector';
import { OtpCodeLoginStep } from '@page/auth-pages/login/login-steps/otp-code-login-step/otp-code-login-step';
import { PushNotificationLoginStep } from '@page/auth-pages/login/login-steps/push-notification-login-step/push-notification-login-step';
import { UserCredentialsStep } from '@page/auth-pages/login/login-steps/user-credentials-step/user-credentials-step';
import { useUserSelector } from '@selector/user/use-user.selector';

export const LoginSteps = observer(() => {
    const [isLoginSelectorVisible, setLoginSelectorVisible] = useState<SetStateAction<boolean>>(false);
    const [manuallyPreferredLoginMethod, setManuallyPreferredLoginMethod] =
        useState<SetStateAction<WebPreferredLoginMethod | null>>(null);
    const [user] = useUserSelector();

    const {
        phone,
        isLoading,
        errorMessage,
        handleUserCredentialsSubmit,
        handleOtpSubmit,
        handleGoogleAuthSubmit,
        handlePushNotificationSubmit,
        onLoginWithAnotherMethod,
        setErrorMessage,
    } = useLoginSteps({ phone: user.phone });

    useEffect(() => {
        if (isTrue(isLoginSelectorVisible)) {
            setErrorMessage('');
        }
    }, [isLoginSelectorVisible]);

    if (isTrue(phone)) {
        if (isTrue(isLoginSelectorVisible)) {
            return (
                <LoginMethodSelector
                    webAvailableLoginMethods={user.webAvailableLoginMethods}
                    setLoginSelectorVisible={setLoginSelectorVisible}
                    onLoginWithAnotherMethod={onLoginWithAnotherMethod}
                    setManuallyPreferredLoginMethod={setManuallyPreferredLoginMethod}
                />
            );
        }

        if (isTrue(manuallyPreferredLoginMethod)) {
            return manuallyPreferredLoginMethod === WebPreferredLoginMethod.PushNotification ? (
                <PushNotificationLoginStep
                    onSubmit={handlePushNotificationSubmit}
                    setLoginSelectorVisible={setLoginSelectorVisible}
                    shouldShowOtherMethodsWithoutTimer={Boolean(manuallyPreferredLoginMethod)}
                />
            ) : manuallyPreferredLoginMethod === WebPreferredLoginMethod.GoogleAuthenticator ? (
                <GoogleAuthLoginStep
                    onSubmit={handleGoogleAuthSubmit}
                    error={errorMessage}
                    setLoginSelectorVisible={setLoginSelectorVisible}
                    isLoading={isLoading}
                    shouldShowOtherMethodsWithoutTimer={Boolean(manuallyPreferredLoginMethod)}
                />
            ) : (
                <OtpCodeLoginStep
                    phone={phone}
                    onSubmit={handleOtpSubmit}
                    error={errorMessage}
                    setLoginSelectorVisible={setLoginSelectorVisible}
                    isLoading={isLoading}
                    onResendOtpCode={() => onLoginWithAnotherMethod(WebPreferredLoginMethod.Sms)}
                />
            );
        }

        return user.webPreferredLoginMethod === WebPreferredLoginMethod.PushNotification ? (
            <PushNotificationLoginStep
                onSubmit={handlePushNotificationSubmit}
                setLoginSelectorVisible={setLoginSelectorVisible}
            />
        ) : user.webPreferredLoginMethod === WebPreferredLoginMethod.GoogleAuthenticator ? (
            <GoogleAuthLoginStep
                onSubmit={handleGoogleAuthSubmit}
                error={errorMessage}
                setLoginSelectorVisible={setLoginSelectorVisible}
                isLoading={isLoading}
            />
        ) : (
            <OtpCodeLoginStep
                phone={phone}
                onSubmit={handleOtpSubmit}
                error={errorMessage}
                setLoginSelectorVisible={setLoginSelectorVisible}
                isLoading={isLoading}
                onResendOtpCode={() => onLoginWithAnotherMethod(WebPreferredLoginMethod.Sms)}
            />
        );
    }

    return <UserCredentialsStep onSubmit={handleUserCredentialsSubmit} error={errorMessage} />;
});
