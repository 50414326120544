import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200, Neutral50, Primary50, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { IsActiveType } from '@shared-type/common';

export const RegistrationFormWrapper = styled.div`
    margin: 16px 0;
`;

export const PrivateOrLegalButtonsWrapper = styled.div`
    margin-top: 4px;
    ${Flex.Row.Style};
    gap: 8px;

    button {
        ${Flex.VerticalCenter.Style};
        gap: 10px;
    }
    @media (max-width: 400px) {
        ${Flex.Column.Style};
    }
`;

export const PrivateOrLegalButtonText = styled.span`
    ${Typography.Paragraph14.Neutral900.Medium.Style};
    white-space: nowrap;
`;

export const PrivateOrLegalButton = styled.button<IsActiveType>`
    border-radius: 8px;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    outline: none;
    transition: all 0.2s ease-out;
    ${({ isActive }) =>
        isActive
            ? `
        background: ${Primary50};
        border: 1px solid ${Primary500};
        ${PrivateOrLegalButtonText}{
            ${Typography.Paragraph14.Primary500.Medium.Style};
        }
    `
            : `
        background: ${Neutral50};
        border: 1px solid ${Neutral200};
    `}
    &:hover {
        background: ${Primary50};
        border: 1px solid ${Primary500};
    }
    &:focus,
    &:active {
        outline: none;
    }
`;
