import { useField } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface RegistrationOnboardingFormErrorProps<T> {
    error?: string;
    name: T;
    setEmailError: Dispatch<SetStateAction<string>>;
}

export const RegistrationOnboardingFormError = <T extends string>({
    error,
    name,
    setEmailError,
}: RegistrationOnboardingFormErrorProps<T>) => {
    const [{ value: email }, , { setError }] = useField(name);

    useEffect(() => {
        setEmailError('');
    }, [email]);

    useEffect(() => {
        setError(error);
    }, [error]);

    return null;
};
