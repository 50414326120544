import React from 'react';

import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { CustomTextarea } from '@shared-atom/elpaso-kit/input/custom-textarea/custom-textarea';
import { Translate } from '@shared-atom/translate/translate';
import { PaymentBeneficiaryRadioButtons } from '@shared-component/payment-beneficiary-radio-buttons/payment-beneficiary-radio-buttons';
import { PaymentFiles } from '@shared-component/payment-files/payment-files';
import { Scroll } from '@shared-component/scroll/scroll';
import { FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { TEXT_AREA_LENGTH } from '@shared-util/constants';

import { PaymentDetailsFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.enum';
import { usePaymentDetailsForm } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.hook';
import {
    PaymentDetailsFormComponentWrapper,
    PaymentDetailsFormWrapper,
    PaymentFormComponentTitle,
    PaymentFormComponentWrapper,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.styles';
import { PAYMENT_DOCUMENT_SIZE } from '@component/modal/modals/payment/payment.modal-options';

export const PaymentDetailsForm = () => {
    const { shouldRenderLegalFields } = usePaymentDetailsForm();

    return (
        <PaymentDetailsFormWrapper>
            <Scroll>
                <PaymentDetailsFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        <PaymentFormComponentTitle>
                            <Translate langKey={LocalizationEnum.PaymentFormBeneficiaryStepBeneficiaryTypeBlockTitle} />
                        </PaymentFormComponentTitle>
                        <PaymentBeneficiaryRadioButtons name={PaymentDetailsFormEnum.BeneficiaryEntityType} />
                    </PaymentFormComponentWrapper>
                </PaymentDetailsFormComponentWrapper>

                <PaymentDetailsFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        {shouldRenderLegalFields ? (
                            <FormRow>
                                <CustomInput
                                    label={LocalizationEnum.PaymentFormBeneficiaryStepCompanyName}
                                    placeholder={LocalizationEnum.PaymentFormBeneficiaryStepCompanyNamePlaceholder}
                                    name={PaymentDetailsFormEnum.BeneficiaryCompanyName}
                                    isMobile
                                />
                            </FormRow>
                        ) : (
                            <FormHalfRow>
                                <CustomInput
                                    placeholder={LocalizationEnum.PaymentFormBeneficiaryStepNamePlaceholder}
                                    label={LocalizationEnum.PaymentFormBeneficiaryStepNameBlockTitle}
                                    name={PaymentDetailsFormEnum.BeneficiaryFirstName}
                                    isMobile
                                />
                                <CustomInput
                                    placeholder={LocalizationEnum.PaymentFormBeneficiaryStepLastNamePlaceholder}
                                    label={LocalizationEnum.PaymentFormBeneficiaryStepLastName}
                                    name={PaymentDetailsFormEnum.BeneficiaryLastName}
                                    isMobile
                                />
                            </FormHalfRow>
                        )}
                    </PaymentFormComponentWrapper>
                </PaymentDetailsFormComponentWrapper>
                <PaymentDetailsFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        <FormRow>
                            <CustomTextarea
                                label={LocalizationEnum.PaymentFormDetailsStepDescription}
                                placeholder={LocalizationEnum.PaymentFormDetailsStepPlaceholder}
                                name={PaymentDetailsFormEnum.Reason}
                                maxLength={TEXT_AREA_LENGTH}
                                isMobile
                            />
                        </FormRow>
                    </PaymentFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        <PaymentFiles
                            name={PaymentDetailsFormEnum.AttachedFiles}
                            limit={PAYMENT_DOCUMENT_SIZE}
                            title={LocalizationEnum.OptionallyAttachFile}
                        />
                    </PaymentFormComponentWrapper>
                </PaymentDetailsFormComponentWrapper>
            </Scroll>
        </PaymentDetailsFormWrapper>
    );
};
