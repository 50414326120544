import { extractBIC } from 'ibantools';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AnyObjectSchema } from 'yup';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { Account, PaymentType } from '@shared-graphql';
import { addDocumentComments } from '@shared-util/add-document-comments';
import { isTrue } from '@shared-util/is-data';

import { PAYMENT_BREADCRUMBS } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { getAmountCurrencyFormValidation } from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.validation';
import {
    paymentFormInitials,
    validationSchemaInitial,
} from '@component/modal/modals/payment/payment-form/payment-form.initial';
import {
    PaymentFormValuesInterface,
    ValidationSchemaTypeInterface,
} from '@component/modal/modals/payment/payment-form/payment-form.interface';
import {
    getBeneficiaryDetails,
    getBeneficiaryValidation,
} from '@component/modal/modals/payment/payment-form/payment-form.utils';
import { usePaymentUpdate } from '@component/modal/modals/payment/payment-form/payment-update-hook/payment-update.hook';
import { PaymentFlowEnum } from '@component/modal/modals/payment/payment.modal.enum';
import { PaymentFlowToPaymentTypeMapping } from '@component/modal/modals/payment/payment.modal.utils';
import { FilterCountriesEnum, useCountries } from '@hook/countries/countries.hook';
import { addComment } from '@model/actions/add-comment';
import { addFileComment } from '@model/actions/add-file-comment';

export const usePaymentFormSteps = (
    accounts: Account[],
    initialPaymentData: PaymentFormValuesInterface,
    currentPaymentFlow: PaymentFlowEnum | null,
    selectedAccountId: string,
    isSignMode: boolean
) => {
    const currentPaymentType = useMemo(
        () => (currentPaymentFlow ? PaymentFlowToPaymentTypeMapping[currentPaymentFlow] : PaymentType.local),
        [currentPaymentFlow]
    );
    const initialPayment = useMemo(
        () =>
            isTrue(initialPaymentData)
                ? initialPaymentData
                : {
                      ...paymentFormInitials,
                      type: currentPaymentType,
                  },
        [currentPaymentType, initialPaymentData]
    );
    const { onOpen } = useContext(InformationModalContext);
    const [activeStep, setActiveStep] = useState(0);
    const [isPaymentError, setPaymentError] = useState(false);

    const [blackListCountries] = useCountries(FilterCountriesEnum.BLACK_LIST);
    const restrictedCountries = useMemo(
        () => blackListCountries?.map(({ alpha2 }) => alpha2) ?? [],
        [blackListCountries]
    ) as string[];

    const [validationSchema, setValidationSchema] = useState<ValidationSchemaTypeInterface>(validationSchemaInitial);
    const initialValidationSchema = useMemo(() => [getAmountCurrencyFormValidation()], []);
    const [validationYupSchemas, setValidationYupSchemas] = useState<AnyObjectSchema[]>(initialValidationSchema);

    const {
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        createPayment,
        isLoadingPayment,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    } = usePaymentUpdate(initialPayment.id, setPaymentError, isSignMode);

    const handleUpdateYupValidationSchemas = (schemas: AnyObjectSchema[]) => setValidationYupSchemas(schemas);

    const onUpdateValidationSchema = (schema: ValidationSchemaTypeInterface) => setValidationSchema(schema);

    const onNextStep = ({ beneficiary }: PaymentFormValuesInterface) => {
        if (isTrue(restrictedCountries.includes(beneficiary?.beneficiaryCountry ?? '')) && activeStep === 1) {
            onOpen(InformationModalEnum.FailureInformationModal);
        } else {
            setActiveStep(prev => prev + 1);
        }
    };
    const onPrevStep = () => setActiveStep(prev => prev - 1);

    const onStep = (step: number) => setActiveStep(step);

    const initialValues = {
        ...initialPayment,
        accountId: selectedAccountId ?? '',
        currency: selectedAccountId ?? '',
    };

    const onSubmit = ({
        accountId,
        attachedFiles,
        beneficiary: beneficiaryFields,
        reason,
        currency: paymentAccountId,
        addressBook,
        fee,
        ...values
    }: PaymentFormValuesInterface) => {
        const { countryCode } = extractBIC(beneficiaryFields.bicSwift ?? '');
        const beneficiary = getBeneficiaryDetails(
            beneficiaryFields,
            countryCode as string
            // validationSchema.sortCode !== null
        );
        const paymentCurrency = accounts.find(account => account.accountId === paymentAccountId)?.currency.code;

        addDocumentComments({
            initialDocuments: initialValues.attachedFiles,
            documents: attachedFiles,
            addComment,
            addFileComment,
        });

        const payment = {
            ...values,
            accountId: paymentAccountId,
            beneficiary,
            currency: paymentCurrency,
            reason,
            documentIds: attachedFiles.map(file => file.key),
        };

        createPayment({
            variables: {
                payment,
            },
        }).catch(() => setPaymentError(true));
    };

    useEffect(() => {
        if (validationSchemaInitial !== validationSchema) {
            const beneficiaryDetailsSchema = getBeneficiaryValidation(validationSchema);
            const amountCurrencySchema = getAmountCurrencyFormValidation(
                validationSchema.acctNumber,
                validationSchema.sortCode,
                validationSchema.purpose,
                validationSchema.beneficiaryInvoiceNumber,
                validationSchema.beneficiaryInvoiceDate,
                validationSchema.beneficiaryCharityNumber
            );

            handleUpdateYupValidationSchemas([amountCurrencySchema, beneficiaryDetailsSchema]);
        }
    }, [validationSchema]);

    const isLastStepActive = activeStep === PAYMENT_BREADCRUMBS.length;

    return {
        onNextStep,
        onPrevStep,
        onStep,
        onOtpSubmit,
        onPaymentSign,
        currentValidationSchema: validationSchema,
        onUpdateValidationSchema,
        activeStep,
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        isLoading: isLoadingPayment,
        onSubmit: isLastStepActive ? onSubmit : onNextStep,
        validationSchema: validationYupSchemas[activeStep],
        initialValues,
        isPaymentError,
        restrictedCountries,
        onSendOtpCode,
    };
};
