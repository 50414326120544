import { combine } from 'flexible-chain';
import styleToCss from 'style-object-to-css-string';
import { CSSObject } from 'styled-components';

import {
    Error100,
    Error50,
    Error500,
    Neutral50,
    Neutral900,
    Primary100,
    Primary50,
    Primary500,
    Primary800,
} from '../../../styles/colors';

export const stylesDefault = {
    Color: {
        backgroundColor: `${Primary500}`,
        color: `${Neutral50}`,
    },
    Black: {
        backgroundColor: `${Neutral900}`,
        color: `${Neutral50}`,
    },
    Ghost: {
        backgroundColor: 'transparent',
        color: `${Primary500}`,
    },
    DangerGhost: {
        backgroundColor: 'transparent',
        color: `${Error500}`,
    },
    ColorStroke: {
        backgroundColor: 'transparent',
        color: `${Primary500}`,
        border: `1px solid ${Primary500}`,
    },
    BlackStroke: {
        backgroundColor: 'transparent',
        color: `${Neutral900}`,
        border: `1px solid ${Neutral900}`,
    },
    WhiteStroke: {
        backgroundColor: 'transparent',
        color: `${Neutral50}`,
        border: `1px solid ${Neutral50}`,
    },
    Tetriary: {
        backgroundColor: `${Primary50}`,
        color: `${Primary500}`,
    },
    Danger: {
        backgroundColor: `${Error50}`,
        color: `${Error500}`,
    },
};
export const stylesHover = {
    ColorHover: {
        backgroundColor: `${Primary800}`,
        color: `${Neutral50}`,
        boxShadow: '0px 25px 50px -12px rgba(0, 125, 100, 0.25)',
    },
    BlackHover: {
        backgroundColor: '#141414',
        color: `${Neutral50}`,
        boxShadow: '0px 20px 40px -52px rgba(16, 24, 40, 0.11);',
    },
    GhostHover: {
        backgroundColor: `${Primary100}`,
        color: `${Primary500}`,
    },
    DangerGhostHover: {
        backgroundColor: `${Error100}`,
        color: `${Error500}`,
    },
    ColorStrokeHover: {
        backgroundColor: `${Primary500}`,
        color: `${Neutral50}`,
    },
    BlackStrokeHover: {
        backgroundColor: `${Neutral900}`,
        color: `${Neutral50}`,
    },
    WhiteStrokeHover: {
        backgroundColor: `${Neutral50}`,
        color: `${Primary500}`,
    },
    TetriaryHover: {
        backgroundColor: `${Primary100}`,
        color: `${Primary500}`,
    },
    DangerHover: {
        backgroundColor: `${Error100}`,
        color: `${Error500}`,
    },
};
export const stylesLoader = {
    ColorLoader: {
        stroke: `${Neutral50}`,
    },
    BlackLoader: {
        stroke: `${Neutral50}`,
    },
    GhostLoader: {
        stroke: `${Primary500}`,
    },
    DangerGhostLoader: {
        stroke: `${Error500}`,
    },
    ColorStrokeLoader: {
        stroke: `${Primary500}`,
    },
    BlackStrokeLoader: {
        stroke: `${Neutral900}`,
    },
    WhiteStrokeLoader: {
        stroke: `${Neutral50}`,
    },
    TetriaryLoader: {
        stroke: `${Primary500}`,
    },
    DangerLoader: {
        stroke: `${Error500}`,
    },
};
export const stylesReset = {
    NoBorder: {
        border: 'none',
    },
    NoShadow: {
        border: 'none',
    },
};

const kitStyles = {
    ...stylesDefault,
    ...stylesHover,
    ...stylesReset,
    ...stylesLoader,
};

const defaultStyles = {
    borderRadius: '8px',
};

const componentResult = {
    Style: (props: CSSObject) => styleToCss({ ...defaultStyles, ...props }),
};

export const ColorStyles = combine(kitStyles, componentResult);
