import { MenuProps } from 'react-select';
import { ListRowProps } from 'react-virtualized/dist/es/List';

import { SelectRowWrapperStyle } from '../../custom-select/custom-select.styles';
import { RowRenderInterface } from '../select-account.props';

export const selectAccountRowWrapper =
    <T,>({ options, setValue }: MenuProps<T, boolean>, rowRender: RowRenderInterface<T>) =>
        ({ style, key, index }: ListRowProps) => {
            const currentItem = options[index];
            const handleChoose = () => setValue(currentItem, 'select-option');

            return (
                <SelectRowWrapperStyle key={key} style={style} onClick={handleChoose}>
                    {rowRender(currentItem, index)}
                </SelectRowWrapperStyle>
            );
        };
