import { Instance, flow, applySnapshot, getSnapshot } from 'mobx-state-tree';

import { GetPayment, GetTransactions, HoldInstructionFilter, Query, TransactionFilter } from '@shared-graphql';
import { initialTransactionsModel } from '@shared-model/transaction-model/transaction-model.initials';

import { apolloClient } from '@connection/apollo-client';
import { TransactionsModel, TransactionsModelType } from '@model/transactoins-model/transactions-model';

const getTransactions = ({
    filters,
    approvalFilters,
    holdFilters,
}: {
    filters: TransactionFilter;
    approvalFilters: TransactionFilter;
    holdFilters: HoldInstructionFilter;
}) =>
    apolloClient
        .query<Pick<Query, 'getTransactions'>>({
            query: GetTransactions,
            variables: { filters, approvalFilters, holdFilters },
        })
        .then(result => result.data);

const loadTransaction = (transactionId: string) =>
    apolloClient
        .query<Pick<Query, 'getPayment'>>({
            query: GetPayment,
            variables: { paymentId: transactionId },
        })
        .then(result => result?.data?.getPayment);

export const transactionsActions = (self: Instance<typeof TransactionsModelType>) => ({
    setTransactionsLoading: (loading: boolean) => {
        self.loading.isTransactionsLoading = loading;
    },
    setSingleTransactionLoading: (loading: boolean) => {
        self.loading.isSingleTransactionLoading = loading;
    },
    loadTransactions: flow(function* loadTransactions(filters) {
        if (self.loading.isTransactionsLoading) {
            return;
        }

        (self as Instance<typeof TransactionsModel>).setTransactionsLoading(true);

        try {
            const transactions = yield getTransactions(filters);

            applySnapshot(self.transactions, transactions?.getTransactions ?? initialTransactionsModel);
            applySnapshot(self.approvalTransactions, transactions?.getApprovalTransactions ?? initialTransactionsModel);
            applySnapshot(self.holdInstructions, transactions?.getHoldInstructions ?? initialTransactionsModel);
        } catch (error) {
            throw new Error(error);
        } finally {
            (self as Instance<typeof TransactionsModel>).setTransactionsLoading(false);
        }
    }),
    loadTransactionDetails: flow(function* loadTransactionDetails(transactionId) {
        if (self.loading.isSingleTransactionLoading) {
            return;
        }

        (self as Instance<typeof TransactionsModel>).setSingleTransactionLoading(true);

        try {
            const { type, ...transaction } = yield loadTransaction(transactionId);

            const transactions = getSnapshot(self);
            const updatedTransactions = {
                ...transactions.transactions,
                elements: transactions.transactions.elements.map(currentTransaction => ({
                    ...currentTransaction,
                    ...(transaction.id === currentTransaction?.id && {
                        ...transaction,
                        paymentType: type,
                    }),
                })),
            };

            applySnapshot(self.transactions, updatedTransactions);
        } catch (error) {
            throw new Error(error);
        } finally {
            (self as Instance<typeof TransactionsModel>).setSingleTransactionLoading(false);
        }
    }),
    clearTransactions: () => {
        applySnapshot(self.transactions, initialTransactionsModel);
        applySnapshot(self.approvalTransactions, initialTransactionsModel);
        applySnapshot(self.holdInstructions, initialTransactionsModel);
    },
});
