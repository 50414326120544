import { useMemo } from 'react';

import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Translate } from '@shared-atom/translate/translate';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { PageEnum } from '@enum/page.enum';
import AppleStore from '@page/main-pages/assets/app_store_btn.svg?react';
import GooglePlay from '@page/main-pages/assets/google_play_btn.svg?react';
import { FooterFollowForm } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form';
import { FooterNavigation } from '@page/main-pages/main-page-common/footer/footer-navigation/footer-navigation';
import { navigationOptions } from '@page/main-pages/main-page-common/footer/footer.options';
import {
    FooterNavLinks,
    FooterContainer,
    FooterInnerContainer,
    FooterNavRow,
    FooterWrapper,
    FooterSocialLinks,
    MobReverse,
    // FooterTelLink,
    FooterAddressLink,
    FooterNavInternalLinks,
    FooterNavLinksColumn,
    FooterTextsRow,
    FooterText,
    FooterMobileAppLinks,
} from '@page/main-pages/main-page-common/footer/footer.styles';
import {
    // telLink,
    socialOptions,
    // phoneNumber,
    addressLink,
} from '@page/main-pages/main-page-common/main-page-common.constants';
import { SocialIcon } from '@page/main-pages/main-page-common/social-icon/social-icon';

export const Footer = () => {
    const { name } = useCurrentNavigation();

    const shouldRenderWhiteBg = useMemo(
        () => [PageEnum.Home, PageEnum.BusinessHome].some(page => page === name),
        [name]
    );

    return (
        <FooterWrapper shouldRenderWhiteBg={shouldRenderWhiteBg}>
            <FooterContainer>
                <FooterInnerContainer>
                    <FooterNavRow>
                        <FooterNavLinks>
                            {/* <ExternalLink href={telLink}> */}
                            {/*    <FooterTelLink>{phoneNumber}</FooterTelLink> */}
                            {/* </ExternalLink> */}
                            <FooterSocialLinks>
                                {socialOptions.map(({ link, Icon }) => (
                                    <SocialIcon link={link} Icon={Icon} key={link} />
                                ))}
                            </FooterSocialLinks>
                            <MobReverse>
                                <ExternalLink href={addressLink}>
                                    <FooterAddressLink>
                                        <Translate langKey={LocalizationEnum.OfficeLocationWebsite} />
                                    </FooterAddressLink>
                                </ExternalLink>
                                <FooterFollowForm />
                            </MobReverse>
                        </FooterNavLinks>
                        <FooterNavLinks>
                            <FooterNavInternalLinks>
                                {navigationOptions.map(item => (
                                    <FooterNavLinksColumn key={item.title}>
                                        <FooterNavigation item={item} />
                                    </FooterNavLinksColumn>
                                ))}
                            </FooterNavInternalLinks>
                            <FooterMobileAppLinks>
                                <ExternalLink href="#" animated>
                                    <AppleStore />
                                </ExternalLink>
                                <ExternalLink href="#" animated>
                                    <GooglePlay />
                                </ExternalLink>
                            </FooterMobileAppLinks>
                        </FooterNavLinks>
                    </FooterNavRow>
                    <FooterTextsRow>
                        <FooterText>
                            <Translate langKey={LocalizationEnum.FooterBottomParagraph1} />
                        </FooterText>
                        <FooterText>
                            <Translate langKey={LocalizationEnum.FooterBottomParagraph2} />
                        </FooterText>
                        <FooterText>
                            <Translate
                                langKey={LocalizationEnum.FooterBottomParagraph3}
                                customRender={linkMarkdownTag}
                            />
                        </FooterText>
                        <FooterText>
                            <Translate langKey={LocalizationEnum.FooterBottomParagraph4} />
                        </FooterText>
                        <FooterText>
                            <Translate langKey={LocalizationEnum.CopyrightTextBeforeYear} />
                            {new Date().getFullYear()}
                            <Translate langKey={LocalizationEnum.CopyrightTextAfterYear} />
                        </FooterText>
                    </FooterTextsRow>
                </FooterInnerContainer>
            </FooterContainer>
        </FooterWrapper>
    );
};
