import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { JoinWaitingList, Mutation } from '@shared-graphql';

import { FooterFollowFormInterface } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.interface';

export const useFooterFollowForm = (): {
    onSubmit: (
    { email }: FooterFollowFormInterface,
    { resetForm }: FormikHelpers<FooterFollowFormInterface>
    ) => Promise<void>;
    isLoading: boolean;
} => {
    const [sendJoiningWaitingList, { loading: isLoading }] =
        useMutation<Pick<Mutation, 'joinWaitingList'>>(JoinWaitingList);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = useCallback(
        async ({ email }: FooterFollowFormInterface, { resetForm }: FormikHelpers<FooterFollowFormInterface>) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const verifyToken = await executeRecaptcha();
            sendJoiningWaitingList({
                variables: {
                    email,
                    message: 'news',
                    token: verifyToken,
                },
            }).finally(() => resetForm());
        },
        [executeRecaptcha]
    );

    return { onSubmit, isLoading };
};
