import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { Account } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PAYMENT_BREADCRUMBS } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { PaymentFlowEnum } from '@component/modal/modals/payment/payment.modal.enum';

import { PaymentFormSteps } from './payment-form-steps/payment-form-steps';
import { usePaymentFormSteps } from './payment-form.hook';
import { PaymentFormValuesInterface } from './payment-form.interface';

interface PaymentFormStepsProps {
    data?: PaymentFormValuesInterface;
    accounts: Account[];
    isSignMode: boolean;
    setModalTitle: Dispatch<SetStateAction<LocalizationEnum>>;
    currentPaymentFlow: PaymentFlowEnum;
    clearPaymentFlow: Dispatch<SetStateAction<null>>;
    selectedAccountId: string;
}

export const PaymentForm = observer(
    ({
        data,
        accounts,
        isSignMode,
        setModalTitle,
        currentPaymentFlow,
        clearPaymentFlow,
        selectedAccountId,
    }: PaymentFormStepsProps) => {
        const {
            initialValues,
            activeStep,
            validationSchema,
            paymentUpdateStep,
            signPaymentError,
            setSignPaymentError,
            onPrevStep,
            onStep,
            onSubmit,
            onOtpSubmit,
            onPaymentSign,
            currentValidationSchema,
            onUpdateValidationSchema,
            isLoading,
            isPaymentError,
            restrictedCountries,
            onSendOtpCode,
        } = usePaymentFormSteps(
            accounts,
            data as PaymentFormValuesInterface,
            currentPaymentFlow,
            selectedAccountId,
            isSignMode
        );

        useEffect(() => void (isSignMode && onStep(PAYMENT_BREADCRUMBS.length)), []);

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
            >
                <PaymentFormSteps
                    accounts={accounts}
                    onStep={onStep}
                    onPrevStep={onPrevStep}
                    onOtpSubmit={onOtpSubmit}
                    onPaymentSign={onPaymentSign}
                    currentValidationSchema={currentValidationSchema}
                    onUpdateValidationSchema={onUpdateValidationSchema}
                    isLoading={isLoading}
                    isSignMode={isSignMode}
                    activeStep={activeStep}
                    signPaymentError={signPaymentError}
                    setSignPaymentError={setSignPaymentError}
                    paymentUpdateStep={paymentUpdateStep}
                    setModalTitle={setModalTitle}
                    isPaymentError={isPaymentError}
                    clearPaymentFlow={clearPaymentFlow}
                    restrictedCountries={restrictedCountries}
                    onSendOtpCode={onSendOtpCode}
                />
            </Formik>
        );
    }
);
