import * as Sentry from '@sentry/react';
import { flow, getParent, Instance } from 'mobx-state-tree';

import { GetActiveCompanyBusiness, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyAddressActionsInterface
    extends Instance<typeof CompanyModelType>,
    ReturnType<typeof companyApplyActions> {}

const queryCompanyService = () =>
    apolloClient
        .query<Pick<Query, 'getActiveCompany'>>({ query: GetActiveCompanyBusiness })
        .then(result => result.data?.getActiveCompany);

export const companyServiceActions = (self: CompanyAddressActionsInterface) => ({
    loadCompanyService: flow(function* loadCompanyService() {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyServiceLoading) {
            return;
        }

        companyModel.setCompanyServiceLoading(true);

        try {
            const result = yield queryCompanyService();

            self.companyService = result.companyService;
        } catch (error) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            companyModel.setCompanyServiceLoading(false);
        }
    }),
});
