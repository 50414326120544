import { Fragment, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { ExternalLink } from '@shared-atom/external-link/external-link';
import { LinkAnchor } from '@shared-atom/link/link-anchor';
import { Translate } from '@shared-atom/translate/translate';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { AnchorEnum } from '@shared-hook/navigation/anchor/anchor.enum';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist, isTrue } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { PageEnum } from '@enum/page.enum';
import { getOurCompanyOptions } from '@page/main-pages/main-page-common/header/components/our-company-select/our-company-select.options';
import {
    SelectItemIcon,
    SelectItemTitle,
    HeaderSelectArrow,
    HeaderSelectOption,
    HeaderSelectOptionsList,
    HeaderSelectWrapper,
    SelectedItem,
    SelectedItemWrapper,
    HeaderSelectArrowWrapper,
} from '@page/main-pages/main-page-common/header/header.styles';

export const OurCompanySelect = ({
    isBusinessPage,
    isMobile = false,
    customClick = noop,
}: {
    isBusinessPage?: boolean;
    isMobile?: boolean;
    customClick?: any;
}) => {
    const navigate = useRouterNavigate();
    const { lang } = useLocalizationStore();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const productSelectRef = useRef<HTMLDivElement | null>(null);
    const ourCompanyOptions = useMemo(() => getOurCompanyOptions(lang), [lang]);
    const handleProductClick = (pageName?: PageEnum) => {
        customClick();
        setMenuOpen(false);
        if (isTrue(pageName)) {
            navigate(pageName as PageEnum);
        }
    };
    useOutsideClick(productSelectRef, () => setMenuOpen(false));

    return (
        <HeaderSelectWrapper
            onClick={() => setMenuOpen(!isMenuOpen)}
            isOpen={isMenuOpen}
            isDarkMode={isBusinessPage}
            isMobile={isMobile}
            ref={productSelectRef}
        >
            <SelectedItemWrapper isMobile={isMobile}>
                <SelectedItem isMobile={isMobile}>
                    <Translate langKey={LocalizationEnum.CommonOurCompany} />
                </SelectedItem>
                <HeaderSelectArrowWrapper isMobile={isMobile} isOpen={isMenuOpen} isDarkMode={isBusinessPage}>
                    <HeaderSelectArrow />
                </HeaderSelectArrowWrapper>
            </SelectedItemWrapper>
            <HeaderSelectOptionsList isMobile={isMobile} isOpen={isMenuOpen}>
                {ourCompanyOptions.map(ourCompanyOption => (
                    <Fragment key={v4()}>
                        {isTrue(ourCompanyOption.externalLink) ? (
                            <ExternalLink href={ourCompanyOption.externalLink as string}>
                                <HeaderSelectOption onClick={() => handleProductClick()} isMobile={isMobile}>
                                    {!isMobile && <SelectItemIcon>{ourCompanyOption.icon}</SelectItemIcon>}
                                    <SelectItemTitle>
                                        <Translate langKey={ourCompanyOption.langKey} />
                                    </SelectItemTitle>
                                </HeaderSelectOption>
                            </ExternalLink>
                        ) : (
                            <>
                                {isExist(ourCompanyOption?.anchor as AnchorEnum) && (
                                    <LinkAnchor
                                        href={ourCompanyOption.url}
                                        anchor={ourCompanyOption.anchor}
                                        target="_self"
                                    >
                                        <HeaderSelectOption isMobile={isMobile}>
                                            {!isMobile && <SelectItemIcon>{ourCompanyOption.icon}</SelectItemIcon>}
                                            <SelectItemTitle>
                                                <Translate langKey={ourCompanyOption.langKey} />
                                            </SelectItemTitle>
                                        </HeaderSelectOption>
                                    </LinkAnchor>
                                )}
                                {!isExist(ourCompanyOption?.anchor as AnchorEnum) && (
                                    <HeaderSelectOption
                                        onClick={() => handleProductClick(ourCompanyOption.page as PageEnum)}
                                        isMobile={isMobile}
                                    >
                                        {!isMobile && <SelectItemIcon>{ourCompanyOption.icon}</SelectItemIcon>}
                                        <SelectItemTitle>
                                            <Translate langKey={ourCompanyOption.langKey} />
                                        </SelectItemTitle>
                                    </HeaderSelectOption>
                                )}
                            </>
                        )}
                    </Fragment>
                ))}
            </HeaderSelectOptionsList>
        </HeaderSelectWrapper>
    );
};
