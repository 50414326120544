import { useLazyQuery, useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';

import {
    CheckUserExist,
    CheckUserExistAnswer,
    Mutation,
    MutationSendRegistrationMailArgs,
    Query,
    SendMailStatus,
    SendRegistrationMail,
} from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isFalse, isString } from '@shared-util/is-data';

import { GtmEventEnum } from '@enum/gtm-event.enum';
import { PageEnum } from '@enum/page.enum';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { getTagManagerEvent } from '@util/gtm.util';

type SendRegistrationMailType = Pick<Mutation, 'sendRegistrationMail'>;
type SendMailStatusType = Omit<SendMailStatus, '__typename'>;

export const useSendRegistrationMail = (): [
    OnEventType<MutationSendRegistrationMailArgs & { firstName: string; lastName: string }>,
    boolean,
    string | undefined,
    Dispatch<SetStateAction<string>>
] => {
    const navigate = useRouterNavigate();
    const [captchaError, setCaptchaError] = useState('');
    const [sendMail, { data, loading: isSendMailLoading, error }] =
        useMutation<SendRegistrationMailType>(SendRegistrationMail);
    const [checkUserExist, { data: checkUserExistResult, loading: isLoadingCheckUserExist }] =
        useLazyQuery<Pick<Query, 'checkUserExist'>>(CheckUserExist);

    const emailRef = useRef<string>('');
    const [isEmailChecked, setEmailCheck] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [emailError, setEmailError] = useState<string>('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    const { setUserData } = useUserActionsSelector();

    const checkUserExistAndReCaptchaVerify = useCallback(
        async (email: string) => {
            try {
                if (!executeRecaptcha) {
                    console.log('Execute recaptcha not yet available');
                    return;
                }

                const verifyToken = await executeRecaptcha();
                checkUserExist({ variables: { email, token: verifyToken } });
            } catch (e) {
                setCaptchaError(e);
                console.log('ERROR', e);
            }
        },
        [executeRecaptcha]
    );

    const sendMailAndReCaptchaVerify = useCallback(
        async (email: string) => {
            try {
                if (!executeRecaptcha) {
                    console.log('Execute recaptcha not yet available');
                    return;
                }

                const verifyToken = await executeRecaptcha();
                sendMail({ variables: { email, token: verifyToken } });
            } catch (e) {
                setCaptchaError(e);
                console.log('ERROR', e);
            }
        },
        [executeRecaptcha]
    );

    const handleSendMail = ({
        email,
        firstName,
        lastName,
    }: MutationSendRegistrationMailArgs & { firstName: string; lastName: string }) => {
        emailRef.current = email;
        setUserEmail(email);
        checkUserExistAndReCaptchaVerify(email);
        setUserData({ email, firstName, lastName });
    };

    const userExistErrorText = useLocalizationText(LocalizationEnum.UserAlreadyExists);
    const { sendRegistrationMail } = (data ?? {}) as SendRegistrationMailType;
    const isLoading = isSendMailLoading || isLoadingCheckUserExist;
    const errorMessage = emailError !== '' ? emailError : error?.message;

    useEffect(() => {
        const isEmailVerified = !!(sendRegistrationMail as SendMailStatusType)?.status || isString(emailRef.current);
        setEmailCheck(isEmailVerified);

        if (isEmailVerified && isFalse(captchaError)) {
            navigate(PageEnum.RegistrationAdditionalFields);
        }
    }, [sendRegistrationMail, emailRef, captchaError]);

    useEffect(() => {
        TagManager.dataLayer(
            getTagManagerEvent(isEmailChecked ? GtmEventEnum.EmailComplete : GtmEventEnum.EmailVerify)
        );
    }, [isEmailChecked]);

    useEffect(() => {
        const checkUserExistAnswer = (checkUserExistResult?.checkUserExist as CheckUserExistAnswer) ?? false;
        if (Boolean(checkUserExistAnswer) === true) {
            if (Boolean(checkUserExistAnswer?.isUserExist) === false) {
                sendMailAndReCaptchaVerify(userEmail);
            } else {
                setEmailError(userExistErrorText);
            }
        }
    }, [checkUserExistResult]);

    return [handleSendMail, isLoading, errorMessage, setEmailError];
};
