import React, { FC, memo, SVGProps } from 'react';

import { ButtonTypeEnum as TypeEnum } from '@shared-atom/button/button-type.enum';
import { ElpasoButton, ElpasoButtonIcon, ElpasoButtonTitle, Loader } from '@shared-atom/elpaso-kit/button/style';
import { ElpasoButtonProps } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { noop } from '@shared-util/noop';

export const Button = memo(
    ({
        title,
        onClick = noop,
        size,
        type,
        isDisabled = false,
        iconAfter = null,
        iconBefore = null,
        titleText = '',
        isSubmit = false,
        isLoading = false,
        style = {},
        animated = true,
    }: ElpasoButtonProps) => {
        const IconComponentBefore = (iconBefore || null) as FC<SVGProps<SVGSVGElement>>;
        const IconComponentAfter = (iconAfter || null) as FC<SVGProps<SVGSVGElement>>;

        return (
            <ElpasoButton
                type={isSubmit ? TypeEnum.Submit : TypeEnum.Button}
                onClick={onClick}
                disabled={isDisabled || isLoading}
                size={size}
                typeStyle={type}
                style={style}
                animated={animated}
            >
                {iconBefore !== null && (
                    <ElpasoButtonIcon isLoading={isLoading} size={size} isBefore>
                        <IconComponentBefore />
                    </ElpasoButtonIcon>
                )}
                <ElpasoButtonTitle size={size} isLoading={isLoading}>
                    {titleText !== '' ? titleText : <Translate langKey={title} />}
                </ElpasoButtonTitle>
                {iconAfter !== null && (
                    <ElpasoButtonIcon size={size} isLoading={isLoading}>
                        <IconComponentAfter />
                    </ElpasoButtonIcon>
                )}
                {isLoading && <Loader typeStyle={type} />}
            </ElpasoButton>
        );
    }
);
