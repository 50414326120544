import { observer } from 'mobx-react-lite';

import { isString } from '@shared-util/is-data';

import { useAuthPartnersSteps } from '@page/auth-pages/auth-partners/auth-partners-steps/auth-partners-steps.hook';
import { OtpCodeLoginStep } from '@page/auth-pages/login/login-steps/otp-code-login-step/otp-code-login-step';
import { UserCredentialsStep } from '@page/auth-pages/login/login-steps/user-credentials-step/user-credentials-step';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const AuthPartnersSteps = observer(() => {
    const [user] = useUserSelector();
    const { setUserPhone, setUserCompanyRole } = useUserActionsSelector();

    const { phone, errorMessage, handleUserCredentialsSubmit, handleOtpSubmit, onResendOtpCode } = useAuthPartnersSteps(
        {
            phone: user.phone,
            setUserPhone,
            setUserCompanyRole,
        }
    );

    const hasPhone = isString(phone);

    return (
        <>
            {hasPhone && (
                <OtpCodeLoginStep
                    phone={phone}
                    onSubmit={handleOtpSubmit}
                    error={errorMessage}
                    onResendOtpCode={onResendOtpCode}
                />
            )}
            {!hasPhone && <UserCredentialsStep onSubmit={handleUserCredentialsSubmit} error={errorMessage} />}
        </>
    );
});
