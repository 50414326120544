import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomCountrySelect } from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select';
import { Translate } from '@shared-atom/translate/translate';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { AuthPageFormTitle } from '@page/auth-pages/common.styles';
import { privateRegistrationAdditionalFieldsFormInitials } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-private/registration-additional-fields-form-private.initial';
import { PrivateRegistrationAdditionalFieldsFormType } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-private/registration-additional-fields-form-private.type';
import { privateRegistrationAdditionalFieldsFormValidationSchema } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-private/registration-additional-fields-form-private.validation';
import { RegistrationAdditionalFieldsFormEnum } from '@page/registration/registration-additional-fields/registration-additional-fields.enum';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const RegistrationAdditionalFieldsPrivateForm = observer(
    ({ countries }: { countries: BaseOptionInterface<string>[] }) => {
        const { setUserData } = useUserActionsSelector();
        const navigate = useRouterNavigate();
        const [{ nationality, residenceCountry, type }] = useUserSelector();

        const initialFormValues = useMemo(
            () =>
                !isTrue(nationality) || !isTrue(residenceCountry)
                    ? privateRegistrationAdditionalFieldsFormInitials
                    : {
                          [RegistrationAdditionalFieldsFormEnum.Nationality]: nationality,
                          [RegistrationAdditionalFieldsFormEnum.ResidenceCountry]: residenceCountry,
                      },
            [nationality, residenceCountry]
        );

        const handleSubmit = (values: PrivateRegistrationAdditionalFieldsFormType) => {
            setUserData({
                [RegistrationAdditionalFieldsFormEnum.Nationality]:
                    values[RegistrationAdditionalFieldsFormEnum.Nationality],
                [RegistrationAdditionalFieldsFormEnum.ResidenceCountry]:
                    values[RegistrationAdditionalFieldsFormEnum.ResidenceCountry],
            });
            navigate(PageEnum.RegistrationPassword);
        };

        return (
            <>
                <AuthPageFormTitle>
                    <Translate
                        langKey={LocalizationEnum.SelectTheCountryOfYourCitizenshipAndTheCountryOfYourResidency}
                    />
                </AuthPageFormTitle>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleSubmit}
                    validationSchema={privateRegistrationAdditionalFieldsFormValidationSchema}
                    validateOnChange={false}
                    enableReinitialize
                >
                    <Form>
                        <FormWrapper>
                            <FormRow>
                                <CustomCountrySelect
                                    label={LocalizationEnum.TheCountryOfCitizenship}
                                    placeholder={LocalizationEnum.CommonChooseCountry}
                                    name={RegistrationAdditionalFieldsFormEnum.Nationality}
                                    countries={countries}
                                    isMobile
                                />
                            </FormRow>
                            <FormRow>
                                <CustomCountrySelect
                                    label={LocalizationEnum.TheCountryOfResidency}
                                    placeholder={LocalizationEnum.CommonChooseCountry}
                                    name={RegistrationAdditionalFieldsFormEnum.ResidenceCountry}
                                    countries={countries}
                                    isMobile
                                />
                            </FormRow>
                        </FormWrapper>
                        <FormButtonsWrapper>
                            <Button
                                onClick={() =>
                                    navigate(PageEnum.OnBoarding, {
                                        activeType: type,
                                    })
                                }
                                type={ButtonTypeEnum.GHOST}
                                size={ButtonSizeEnum.L}
                                title={LocalizationEnum.CommonBack}
                                iconBefore={ArrowLeft}
                            />
                            <Button
                                size={ButtonSizeEnum.L}
                                type={ButtonTypeEnum.COLOR}
                                title={LocalizationEnum.RegistrationLegalCompanyButtonTitle}
                                isSubmit
                            />
                        </FormButtonsWrapper>
                    </Form>
                </Formik>
            </>
        );
    }
);
