import { FC, useState } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    AuthPageContentDescriptionText,
    AuthPageContentWrapper,
    AuthPageFormTitle,
} from '@page/auth-pages/common.styles';
import ForgotPasswordPage from '@page/auth-pages/forgot-password/forgot-password';

import {
    UserCredentialsStepForm,
    UserCredentialsStepFormProps,
} from './user-credentials-step-form/user-credentials-step-form';

const loginTitle = [LocalizationEnum.LoginFormWelcome];

export const UserCredentialsStep: FC<UserCredentialsStepFormProps> = ({ onSubmit, error }) => {
    const [isForgot, setValueForgot] = useState(false);
    return isForgot ? (
        <ForgotPasswordPage />
    ) : (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={loginTitle} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionText>
                <AuthPageContentDescriptionText>
                    <Translate langKey={LocalizationEnum.LoginFormDescription} />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionText>
            <UserCredentialsStepForm onSubmit={onSubmit} error={error} setValueForgot={setValueForgot} />
        </AuthPageContentWrapper>
    );
};
