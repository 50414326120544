import { ApolloClient, DefaultOptions, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

// import { GraphqlErrorCodesEnum } from '@shared-enum/graphql-error-codes.enum';
import { setLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
// import { getGraphQLErrorCode } from '@shared-util/get-graphql-error-code';

import { PageEnum } from '@enum/page.enum';

const cache = new InMemoryCache({
    addTypename: false,
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const errorLink = onError(({ graphQLErrors }) => {
    if (Array.isArray(graphQLErrors)) {
        // const errorCode = getGraphQLErrorCode(graphQLErrors);

        if (window.location.pathname !== PageEnum.Login) {
            setLocalStorageValue('redirectPage', PageEnum.Login);
        }

        // if (
        //     [GraphqlErrorCodesEnum.AuthenticationError, GraphqlErrorCodesEnum.NotAuthenticationError].includes(
        //         errorCode as GraphqlErrorCodesEnum
        //     )
        // ) {
        //     document.location.href = PageEnum.Login;
        // }
    }
});
const apiLink = createHttpLink({
    uri: import.meta.env.VITE_API_URL,
}) as unknown as ApolloLink;

const uploadLink = createUploadLink({
    uri: `${import.meta.env.VITE_API_URL}/upload`,
    headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Apollo-Require-Preflight': 'true',
    },
}) as unknown as ApolloLink;

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: { errorPolicy: 'all' },
};

export const apolloClient = new ApolloClient({
    cache,
    link: from([errorLink, apiLink]),
    queryDeduplication: false,
    defaultOptions,
});

export const apolloUploadClient = new ApolloClient({
    cache,
    link: from([errorLink, uploadLink]),
    queryDeduplication: false,
    defaultOptions,
});
