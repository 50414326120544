import { FC, SVGProps } from 'react';

import { LocalizationEnum } from '@shared-locale/localization.enum';

export enum ButtonSizeEnum {
    M = 'm',
    L = 'l',
    XL = 'xl',
}
export enum ButtonTypeEnum {
    COLOR = 'color',
    BLACK = 'black',
    GHOST = 'ghost',
    COLOR_STROKE = 'color-stroke',
    BLACK_STROKE = 'black-stroke',
    WHITE_STROKE = 'white-stroke',
    TETRIARY = 'tetriary',
    DANGER = 'danger',
    DANGER_GHOST = 'danger-ghost',
}

export interface ElpasoButtonProps {
    title: string | LocalizationEnum;
    size: ButtonSizeEnum;
    type: ButtonTypeEnum;
    onClick?: any;
    iconAfter?: FC<SVGProps<SVGSVGElement>> | null;
    iconBefore?: FC<SVGProps<SVGSVGElement>> | null;
    isDisabled?: boolean;
    titleText?: string;
    isSubmit?: boolean;
    isLoading?: boolean;
    style?: any;
    animated?: boolean;
}
