import { useLazyQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import { GetPdfTransactions, GetReport, GetReportPresignedUrl, Query, ReportStatus } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { StatementFormInterface } from '@component/modal/modals/request-statement/statement-form/statement-form.interface';

export const useReportUrlGetting = () => {
    const { onClose } = useContext(ModelContext);
    const { onOpen } = useContext(InformationModalContext);

    const intervalId = useRef<number | null>(null);
    const globalWaitingTimerId = useRef<number | null>(null);
    const [isReportUrlLoading, setReportUrlLoading] = useState(false);
    const [url, setUrl] = useState('');

    const [getPDFTransactions, { data: getPDFTransactionsData, error: getPDFTransactionsError }] =
        useLazyQuery<Pick<Query, 'getPDFTransactions'>>(GetPdfTransactions);
    const [getReport, { data: getReportData, error: getReportError }] =
        useLazyQuery<Pick<Query, 'getReport'>>(GetReport);
    const [getReportPresignedUrl, { data: getReportPresignedUrlData, error: getReportPresignedUrlError }] =
        useLazyQuery<Pick<Query, 'getReportPresignedUrl'>>(GetReportPresignedUrl);

    const finishProcessing = useCallback(() => {
        clearTimeout(globalWaitingTimerId.current as number);
        setReportUrlLoading(false);
        onClose();
    }, []);

    useEffect(() => {
        if (isTrue(url)) {
            window.location.href = url;
            finishProcessing();
        }
    }, [url]);

    useEffect(() => {
        if (isTrue(getReportPresignedUrlData?.getReportPresignedUrl.url)) {
            setUrl(getReportPresignedUrlData?.getReportPresignedUrl.url as string);
        }
    }, [getReportPresignedUrlData]);

    useEffect(() => {
        if (getReportData?.getReport.status === ReportStatus.done) {
            getReportPresignedUrl({ variables: { reportId: getPDFTransactionsData?.getPDFTransactions.reportId } });
        }
        if (getReportData?.getReport.status === ReportStatus.failed) {
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [getReportData, getPDFTransactionsData]);

    useEffect(() => {
        if (isTrue(getPDFTransactionsData?.getPDFTransactions.reportId)) {
            intervalId.current = setInterval(
                () => getReport({ variables: { getReportId: getPDFTransactionsData?.getPDFTransactions.reportId } }),
                5000
            );
        }

        if ([ReportStatus.failed, ReportStatus.done].includes(getReportData?.getReport.status as ReportStatus)) {
            clearInterval(intervalId.current as number);
        }

        return () => clearInterval(intervalId.current as number);
    }, [getPDFTransactionsData, getReportData]);

    useEffect(() => {
        if (getPDFTransactionsError || getReportError || getReportPresignedUrlError) {
            clearInterval(intervalId.current as number);
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [getPDFTransactionsError, getReportError, getReportPresignedUrlError]);

    const handleSubmit = useCallback(({ gte, lte, accountId }: StatementFormInterface) => {
        setReportUrlLoading(true);
        globalWaitingTimerId.current = setTimeout(() => {
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.SorrySomethingWentWrongPleaseTryAgainLater,
            });
        }, 60 * 1000);
        getPDFTransactions({
            variables: {
                filters: {
                    accountId,
                    updatedAt: {
                        gte: gte !== undefined ? startOfDay(gte) : null,
                        lte: lte !== undefined ? endOfDay(lte) : null,
                    },
                },
            },
        });
    }, []);

    return { isReportUrlLoading, handleSubmit };
};
