import { Instance, flow } from 'mobx-state-tree';

import { Currency, GetCurrencies, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { CurrenciesModel, CurrenciesModelType } from '@model/currencies-model/currencies-model';

const queryCurrencies = () =>
    apolloClient
        .query<Pick<Query, 'getCurrencies'>>({ query: GetCurrencies })
        .then(result => result.data.getCurrencies);

export const currenciesActions = (self: Instance<typeof CurrenciesModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    loadActiveCurrencies: flow(function* loadCurrencies() {
        if (self.loading.isLoading) {
            return;
        }

        (self as Instance<typeof CurrenciesModel>).setLoading(true);

        try {
            const currencies = yield queryCurrencies();
            const activeCurrencies = currencies.filter(({ isActive }: Currency) => isActive);
            activeCurrencies.forEach((currency: Currency) => {
                self.elements.set(currency.code, currency);
            });
        } catch (error) {
            throw new Error(error);
        } finally {
            (self as Instance<typeof CurrenciesModel>).setLoading(false);
        }
    }),
});
