import { DEFAULT_CURRENCY } from '@shared-util/constants';

import { PaymentToCardDetailsFormEnum } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.enum';
import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';

export const paymentToCardFormInitials: PaymentToCardFormValuesInterface = {
    id: null,
    [PaymentToCardDetailsFormEnum.PaymentCurrency]: DEFAULT_CURRENCY,
    [PaymentToCardDetailsFormEnum.Reason]: '',
    [PaymentToCardDetailsFormEnum.AttachedFiles]: [],
    [PaymentToCardDetailsFormEnum.CardNumber]: '',
    [PaymentToCardDetailsFormEnum.ReceiverFirstName]: '',
    [PaymentToCardDetailsFormEnum.ReceiverSurname]: '',
    [PaymentToCardDetailsFormEnum.Region]: '',
    [PaymentToCardDetailsFormEnum.Nationality]: '',
};
