import FilePencilAlt from '@shared-asset/vector/file-pencil-alt.svg?react';
import InfoCircle from '@shared-asset/vector/info-circle.svg?react';
import TableTree from '@shared-asset/vector/table-tree.svg?react';
import Users from '@shared-asset/vector/users-2.svg?react';
import { AnchorEnum } from '@shared-hook/navigation/anchor/anchor.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { LangKeysEnum } from '@shared-store/store-localization.interface';

import { PageEnum } from '@enum/page.enum';
import { OurCompanyEnum } from '@page/main-pages/main-page-common/header/components/our-company-select/our-company-select.enum';
import { blogLink } from '@page/main-pages/main-page-common/main-page-common.constants';

export const getOurCompanyOptions = (lang: LangKeysEnum) => [
    {
        value: OurCompanyEnum.AboutUs,
        langKey: LocalizationEnum.CommonAboutUs,
        page: PageEnum.AboutUs,
        icon: <InfoCircle />,
    },
    {
        value: OurCompanyEnum.OurTeam,
        langKey: LocalizationEnum.CommonOurTeam,
        url: PageEnum.AboutUs,
        anchor: AnchorEnum.OurTeam,
        icon: <Users />,
    },
    {
        value: OurCompanyEnum.NewAndBlogs,
        langKey: LocalizationEnum.CommonNewsAndBlog,
        icon: <TableTree />,
        externalLink: `${blogLink}${lang}`,
    },
    {
        value: OurCompanyEnum.Careers,
        langKey: LocalizationEnum.CareersPageHeading,
        page: PageEnum.Careers,
        icon: <FilePencilAlt />,
    },
];
