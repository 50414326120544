import { MenuProps } from 'react-select';
import { List } from 'react-virtualized';

import { customSelectRowWrapper } from '../custom-select-row-wrapper/custom-select-row-wrapper';
import { RowRenderInterface } from '../custom-select.props';

interface MenuListProps<T> {
    menuHeight: number;
    rowHeight: number;
    rowCount: number;
    rowRender: RowRenderInterface<T>;
    menuWidth: number;
}

export const CustomSelectMenuList =
    <T,>({ menuWidth, menuHeight, rowHeight, rowCount, rowRender }: MenuListProps<T>) =>
        (props: MenuProps<T, boolean>) =>
            (
                <List
                    width={menuWidth}
                    height={menuHeight}
                    rowHeight={rowHeight}
                    rowCount={rowCount}
                    rowRenderer={customSelectRowWrapper(props, rowRender)}
                />
            );
