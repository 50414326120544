import { FC } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { OtpSendForm } from '@shared-form/otp-send-form/otp-send-form';
import { OtpSendFormProps } from '@shared-form/otp-send-form/otp-send-form.interface';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    AuthPageButtonWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
    AuthPageFormWrapper,
} from '@page/auth-pages/common.styles';

export const OtpCodeLoginStep: FC<OtpSendFormProps> = ({
    phone,
    error,
    onSubmit,
    setLoginSelectorVisible,
    isLoading,
    onResendOtpCode,
}) => (
    <AuthPageContentWrapper>
        <AuthPageFormTitle>
            <Translate langKey={LocalizationEnum.RegistrationOtpTitle} />
        </AuthPageFormTitle>
        <AuthPageFormWrapper isLoading={isLoading}>
            <OtpSendForm phone={phone} error={error} onSubmit={onSubmit} onResendOtpCode={onResendOtpCode} />
        </AuthPageFormWrapper>
        {setLoginSelectorVisible !== undefined && (
            <AuthPageButtonWrapper>
                <Button
                    onClick={() => setLoginSelectorVisible(true)}
                    type={ButtonTypeEnum.GHOST}
                    size={ButtonSizeEnum.L}
                    title={LocalizationEnum.CommonOtherMethods}
                />
            </AuthPageButtonWrapper>
        )}
    </AuthPageContentWrapper>
);
