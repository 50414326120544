import { CheckboxWrapper } from '@ui/FormControls/checkbox';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import FormRow from '@shared-component/elpaso-ui/formrow/FormRow';
import { CustomInput } from '@shared-component/input/customInput';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

import { GoogleRecaptchaInfo } from '@component/google-recaptcha-info/google-recaptcha-info';
import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { RegistrationOnboardingFormError } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form-error';
import { registrationOnboardingFormInitialValues } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form.initial';
import {
    RegistrationOnboardingFormEnum,
    RegistrationOnboardingType,
    registrationOnboardingFormSchema,
} from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form.options';
import { useUserSelector } from '@selector/user/use-user.selector';

interface RegistrationOnboardingFromProps {
    onSubmit: OnEventType<RegistrationOnboardingType>;
    isSendEmailLoading: boolean;
    error?: string;
    setEmailError: Dispatch<SetStateAction<string>>;
}

export const RegistrationOnboardingForm = observer(
    ({ onSubmit, isSendEmailLoading, error, setEmailError }: RegistrationOnboardingFromProps) => {
        const emailTitle = useLocalizationText(LocalizationEnum.CommonEmail);
        const firstNameTitle = useLocalizationText(LocalizationEnum.CommonFirstName);
        const lastNameTitle = useLocalizationText(LocalizationEnum.CommonLastName);
        const enterYourMailTitle = useLocalizationText(LocalizationEnum.EnterYourMail);
        const enterFirstName = useLocalizationText(LocalizationEnum.EnterFirstName);
        const enterLastName = useLocalizationText(LocalizationEnum.EnterLastName);
        const [isChecked, setCheckedValue] = useState(false);
        const [{ email, firstName, lastName }] = useUserSelector();

        const initialFormValues = useMemo(
            () =>
                !isTrue(email) || !isTrue(firstName) || !isTrue(lastName)
                    ? registrationOnboardingFormInitialValues
                    : {
                        [RegistrationOnboardingFormEnum.Email]: email,
                        [RegistrationOnboardingFormEnum.FirstName]: firstName,
                        [RegistrationOnboardingFormEnum.LastName]: lastName,
                        [RegistrationOnboardingFormEnum.Token]: '',
                    },
            [email, firstName, lastName]
        );

        const handleChange = () => {
            setCheckedValue(!isChecked);
        };
        return (
            <Formik<RegistrationOnboardingType>
                initialValues={initialFormValues}
                onSubmit={onSubmit}
                validationSchema={registrationOnboardingFormSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <Form>
                    <RegistrationOnboardingFormError
                        error={error}
                        name={RegistrationOnboardingFormEnum.Email}
                        setEmailError={setEmailError}
                    />
                    <FormRow>
                        <CustomInput
                            name={RegistrationOnboardingFormEnum.Email}
                            title={emailTitle}
                            placeholder={enterYourMailTitle}
                            autofocus
                        />
                    </FormRow>
                    <FormRow>
                        <CustomInput
                            name={RegistrationOnboardingFormEnum.FirstName}
                            title={firstNameTitle}
                            placeholder={enterFirstName}
                        />
                    </FormRow>
                    <CustomInput
                        name={RegistrationOnboardingFormEnum.LastName}
                        title={lastNameTitle}
                        placeholder={enterLastName}
                    />
                    <CheckboxWrapper
                        label=""
                        subLabel={
                            <Translate langKey={LocalizationEnum.ReadPrivacyPolicy} customRender={linkMarkdownTag} />
                        }
                        isChecked={isChecked}
                        onChange={handleChange}
                        forId="privacy-policy"
                    />
                    <Button
                        isDisabled={isSendEmailLoading || !isChecked}
                        title={LocalizationEnum.RegistrationBtn}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        style={{ marginTop: '24px', width: '100%' }}
                    />
                    <GoogleRecaptchaInfo />
                </Form>
            </Formik>
        );
    }
);
