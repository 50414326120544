import { useRef, useState } from 'react';
import { v4 } from 'uuid';

import { Translate } from '@shared-atom/translate/translate';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { noop } from '@shared-util/noop';

import { PageEnum } from '@enum/page.enum';
import { productOptions } from '@page/main-pages/main-page-common/header/components/product-select/product-select.options';
import {
    SelectItemIcon,
    SelectItemTitle,
    HeaderSelectArrow,
    HeaderSelectOption,
    HeaderSelectOptionsList,
    HeaderSelectWrapper,
    SelectedItem,
    SelectedItemWrapper,
    HeaderSelectArrowWrapper,
} from '@page/main-pages/main-page-common/header/header.styles';

export const ProductSelect = ({
    isBusinessPage = false,
    isMobile = false,
    customClick = noop,
}: {
    isBusinessPage?: boolean;
    isMobile?: boolean;
    customClick?: any;
}) => {
    const navigate = useRouterNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const productSelectRef = useRef<HTMLDivElement | null>(null);
    const handleProductClick = (pageName: PageEnum) => {
        customClick();
        setMenuOpen(false);
        navigate(pageName);
    };
    useOutsideClick(productSelectRef, () => setMenuOpen(false));

    return (
        <HeaderSelectWrapper
            onClick={() => setMenuOpen(!isMenuOpen)}
            isOpen={isMenuOpen}
            isDarkMode={isBusinessPage}
            isMobile={isMobile}
            ref={productSelectRef}
        >
            <SelectedItemWrapper isMobile={isMobile}>
                <SelectedItem isMobile={isMobile}>
                    <Translate langKey={LocalizationEnum.FooterNavProductTitle} />
                </SelectedItem>
                <HeaderSelectArrowWrapper isMobile={isMobile} isOpen={isMenuOpen} isDarkMode={isBusinessPage}>
                    <HeaderSelectArrow />
                </HeaderSelectArrowWrapper>
            </SelectedItemWrapper>
            <HeaderSelectOptionsList isMobile={isMobile} isOpen={isMenuOpen}>
                {productOptions.map(productOption => (
                    <HeaderSelectOption
                        key={v4()}
                        onClick={() => handleProductClick(productOption.page)}
                        isMobile={isMobile}
                    >
                        {!isMobile && <SelectItemIcon>{productOption.icon}</SelectItemIcon>}
                        <SelectItemTitle>
                            <Translate langKey={productOption.langKey} />
                        </SelectItemTitle>
                    </HeaderSelectOption>
                ))}
            </HeaderSelectOptionsList>
        </HeaderSelectWrapper>
    );
};
