// import mastercardIcon from '@shared-asset/mastercard.svg';
// import sendsIcon from '@shared-asset/sends.svg';
import sepaIcon from '@shared-asset/sepa.svg';
import swiftIcon from '@shared-asset/swift.svg';
import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PaymentFlowEnum } from '@component/modal/modals/payment/payment.modal.enum';

export const paymentFlowSelectorOptions = [
    {
        paymentFlow: PaymentFlowEnum.Sepa,
        title: LocalizationEnum.WithinSepaZone,
        tooltipText: LocalizationEnum.MakeFastSecureAndCostEffectiveBankTransfersInEur,
        icon: sepaIcon,
        availableCurrencies: [CurrenciesEnum.EUR],
        dataFor: TooltipDataForEnum.PaymentFlowSepaZone,
    },
    {
        paymentFlow: PaymentFlowEnum.Swift,
        title: LocalizationEnum.CommonInternationalPayment,
        tooltipText: LocalizationEnum.TransferFundsGloballyInEurUsdAndGbpWithSwiftPayments,
        icon: swiftIcon,
        availableCurrencies: [CurrenciesEnum.EUR, CurrenciesEnum.GBP, CurrenciesEnum.USD],
        dataFor: TooltipDataForEnum.PaymentFlowSwift,
    },
    {
        paymentFlow: PaymentFlowEnum.Local,
        title: LocalizationEnum.CommonUkLocalPayment,
        tooltipText: LocalizationEnum.MakeFastSecureAndCostEffectiveBankTransfersToUkBankAccountsInGbp,
        availableCurrencies: [CurrenciesEnum.GBP],
        dataFor: TooltipDataForEnum.PaymentFlowLocal,
    },
    // {
    //     paymentFlow: PaymentFlowEnum.Sends,
    //     title: LocalizationEnum.CommonToSendsUser,
    //     tooltipText: LocalizationEnum.FreeOfChargeWireTransferFromYourAccountToAnotherSendsUserAccount,
    //     icon: sendsIcon,
    //     availableCurrencies: [CurrenciesEnum.EUR, CurrenciesEnum.GBP, CurrenciesEnum.USD],
    //     dataFor: TooltipDataForEnum.PaymentFlowSends,
    // },
    // {
    //     paymentFlow: PaymentFlowEnum.CardWithdrawal,
    //     title: LocalizationEnum.CommonPayoutToCard,
    //     tooltipText: LocalizationEnum.PaymentInEurOrGbpFromYourAccountToBankCard,
    //     icon: mastercardIcon,
    //     availableCurrencies: [CurrenciesEnum.EUR, CurrenciesEnum.GBP],
    //     dataFor: TooltipDataForEnum.PaymentFlowCardWithdrawal,
    // },
];
