import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import IconLegal from '@shared-asset/vector/suitcase.svg?react';
import IconPrivate from '@shared-asset/vector/user-1.svg?react';
import { Translate } from '@shared-atom/translate/translate';
import { UserType } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

import { useUserTypeUpdate } from '@hook/user/user-type-update.hook';
import {
    AuthPageContentDescriptionText,
    AuthPageContentDescriptionWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
} from '@page/auth-pages/common.styles';
import { RegistrationOnboardingForm } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form';
import { RegistrationOnboardingType } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form.options';
import {
    PrivateOrLegalButton,
    PrivateOrLegalButtonsWrapper,
    PrivateOrLegalButtonText,
    RegistrationFormWrapper,
} from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-send-email.styles';

interface RegistrationOnboardingSendEmailProps {
    onSendEmail: OnEventType<RegistrationOnboardingType>;
    isSendEmailLoading: boolean;
    error?: string;
    setEmailError: Dispatch<SetStateAction<string>>;
}

export const RegistrationOnboardingSendEmail = ({
    onSendEmail,
    error,
    isSendEmailLoading,
    setEmailError,
}: RegistrationOnboardingSendEmailProps) => {
    const handleUserTypeUpdate = useUserTypeUpdate();
    const {
        searchParams: { activeType },
    } = useCurrentNavigation();
    const [typeBtn, setTypeBtn] = useState<UserType>(UserType.Legal);
    const title = isTrue(typeBtn === UserType.Legal)
        ? LocalizationEnum.RegistrationFormTitle1
        : LocalizationEnum.RegistrationCheckMailTitleIndividual;

    useEffect(() => {
        const userTypeToSet = isTrue(activeType) ? activeType : UserType.Legal;
        setTypeBtn(userTypeToSet as UserType);
        handleUserTypeUpdate(userTypeToSet as UserType);
    }, []);

    const handleBtnLegal = () => {
        setTypeBtn(UserType.Legal);
        handleUserTypeUpdate(UserType.Legal);
    };

    const handleBtnPrivate = () => {
        setTypeBtn(UserType.Private);
        handleUserTypeUpdate(UserType.Private);
    };

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={title} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionWrapper>
                <AuthPageContentDescriptionText>
                    <Translate langKey={LocalizationEnum.RegistrationFormDescription1} />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionWrapper>
            <PrivateOrLegalButtonsWrapper>
                <PrivateOrLegalButton onClick={handleBtnLegal} isActive={typeBtn === UserType.Legal}>
                    <IconLegal />
                    <PrivateOrLegalButtonText>
                        <Translate langKey={LocalizationEnum.FaqSectionTab1} />
                    </PrivateOrLegalButtonText>
                </PrivateOrLegalButton>
                <PrivateOrLegalButton onClick={handleBtnPrivate} isActive={typeBtn === UserType.Private}>
                    <IconPrivate />
                    <PrivateOrLegalButtonText>
                        <Translate langKey={LocalizationEnum.CommonPersonalAccount} />
                    </PrivateOrLegalButtonText>
                </PrivateOrLegalButton>
            </PrivateOrLegalButtonsWrapper>
            <RegistrationFormWrapper>
                <RegistrationOnboardingForm
                    onSubmit={onSendEmail}
                    isSendEmailLoading={isSendEmailLoading}
                    error={error}
                    setEmailError={setEmailError}
                />
            </RegistrationFormWrapper>
        </AuthPageContentWrapper>
    );
};
