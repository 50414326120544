import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { RefObject, useRef, useState } from 'react';
import ReactSelect, { SelectComponentsConfig, components } from 'react-select';
import { ElRef } from 'react-select/base';
import { StateManager } from 'react-select/src/stateManager';

import {
    containerStyle,
    controlStyle,
    inputStyle,
    placeholderStyle,
    selectMenuStyle,
    SelectWrapper,
    ErrorWrapper,
    Wrapper,
    LabelWrapper,
    singleValueStyle,
    valueContainerStyle,
    indicatorSeparatorStyle,
    indicatorsContainerStyle,
    noOptionsMessageStyle,
} from '@shared-atom/elpaso-kit/custom-select/custom-select.styles';
import { SelectAccountMenuList } from '@shared-atom/elpaso-kit/select-account/select-account-menu-list/select-account-menu-list';
import { selectRowRender } from '@shared-atom/elpaso-kit/select-account/select-account.initial';
import { NodeOptionInterface, SelectAccountProps } from '@shared-atom/elpaso-kit/select-account/select-account.props';
import { FieldError } from '@shared-component/field-error/field-error';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useBoundingSize } from '@shared-hook/utils/use-bounding-size.hook';
import { isExist } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

export const SelectAccount = observer(
    <T extends NodeOptionInterface>({
        name,
        title,
        label = '',
        options = [],
        rowHeight = 57,
        rowCountView = 5,
        menuHeight,
        menuWidth,
        isUseVal,
        isDisabled = false,
        rowRender = selectRowRender,
        onChange = noop,
        valueRender,
        isMobile,
        ...selectProps
    }: SelectAccountProps<T>) => {
        const [field, { error }, { setValue, setError }] = useField(name);

        const [isMenuOpened, setMenuOpened] = useState(false);
        const selectRef = useRef() as RefObject<StateManager<T, boolean>>;
        const controlSize = useBoundingSize(selectRef.current?.select.controlRef);
        const rowCount = options.length;
        const hasError = isExist(error);

        const menuListHeight = menuHeight ?? rowHeight * (rowCountView > rowCount ? rowCount : rowCountView);

        const handleChange = (selectValue: any) => {
            setValue(selectValue?.value);
            onChange();
        };

        const toggleFocus = (isOpen: boolean) => () => {
            setMenuOpened(isOpen);
            if (hasError) {
                setError(undefined);
            }
        };

        const selectComponents = {
            SingleValue: valueRender ?? components.SingleValue,
            MenuList:
                rowCount > 0
                    ? SelectAccountMenuList<T>({
                          menuHeight: menuListHeight,
                          rowHeight,
                          rowCount,
                          rowRender,
                          menuWidth: menuWidth ?? controlSize?.width ?? 0,
                      })
                    : components.MenuList,
        } as unknown as SelectComponentsConfig<T, boolean>;

        const optionValue = options.find(({ value }) => value === (field.value as any as string));
        const placeholder = useLocalizationText(title);
        const localizedLabel = useLocalizationText(label);

        return (
            <Wrapper>
                {localizedLabel !== '' && <LabelWrapper>{localizedLabel}</LabelWrapper>}
                <SelectWrapper>
                    <ReactSelect
                        {...selectProps}
                        isSearchable={false}
                        isDisabled={isDisabled}
                        ref={selectRef as ElRef}
                        placeholder={placeholder}
                        value={optionValue ?? null}
                        options={options}
                        components={selectComponents as any}
                        onChange={handleChange}
                        onInputChange={onChange}
                        onMenuOpen={toggleFocus(true)}
                        onMenuClose={toggleFocus(false)}
                        openMenuOnFocus
                        styles={{
                            container: containerStyle,
                            placeholder: placeholderStyle(isMobile),
                            valueContainer: valueContainerStyle(),
                            singleValue: singleValueStyle(),
                            input: inputStyle(),
                            menu: selectMenuStyle(),
                            indicatorSeparator: indicatorSeparatorStyle(),
                            indicatorsContainer: indicatorsContainerStyle(isDisabled),
                            control: controlStyle(isMenuOpened, hasError, isMobile),
                            noOptionsMessage: noOptionsMessageStyle,
                        }}
                    />
                </SelectWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </Wrapper>
        );
    }
);
