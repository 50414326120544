import { observer } from 'mobx-react-lite';

import { useSendRegistrationMail } from '@hook/user/send-registration-mail.hook';

import { RegistrationOnboardingSendEmail } from './registration-onboarding-send-email/registration-onboarding-send-email';
import { RegistrationOnboardingWrapper } from './registration-onboarding.styles';

const RegistrationOnboarding = observer(() => {
    const [handleSendMail, isLoading, error, setEmailError] = useSendRegistrationMail();

    return (
        <RegistrationOnboardingWrapper>
            <RegistrationOnboardingSendEmail
                onSendEmail={handleSendMail}
                isSendEmailLoading={isLoading}
                error={error}
                setEmailError={setEmailError}
            />
        </RegistrationOnboardingWrapper>
    );
});

export default RegistrationOnboarding;
