import { useMemo } from 'react';
import ReactGA from 'react-ga4';

import logo from '@shared-asset/logo_black.svg';
import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { GAEventActionsEnum, GAEventCategoryEnum } from '@enum/ga-event.enum';
import { PageEnum } from '@enum/page.enum';
import { registrationPages } from '@page/registration/registration.constants';
import classes from '@page/sign-in/styles/header.module.css';
import cl from '@page/sign-in/styles/signWrapper.module.css';

export const Header = () => {
    const { name } = useCurrentNavigation();
    const isPagesForShowingSignInFlow = useMemo(() => registrationPages.has(name), [name]);

    return (
        <div className={classes.mainWrapper}>
            <div className={cl.container}>
                <div className={classes.headerWrapper}>
                    <div className={classes.logoSection}>
                        <Link route={PageEnum.Home}>
                            <img alt="logo" src={logo} />
                        </Link>
                    </div>
                    {name !== PageEnum.Verification && (
                        <div className={classes.registrationPart}>
                            {isTrue(isPagesForShowingSignInFlow) ? (
                                <p>
                                    <Translate langKey={LocalizationEnum.HeaderRightTextSignin} />
                                </p>
                            ) : (
                                <p>
                                    <Translate langKey={LocalizationEnum.HeaderRightTextRegistration} />
                                </p>
                            )}

                            <span>
                                {isTrue(isPagesForShowingSignInFlow) ? (
                                    <Link route={PageEnum.Login}>
                                        <Translate langKey={LocalizationEnum.RegistrationFormTitleSignin} />
                                    </Link>
                                ) : (
                                    <Link
                                        route={PageEnum.OnBoarding}
                                        onClick={() => {
                                            ReactGA.event({
                                                category: GAEventCategoryEnum.User,
                                                action: GAEventActionsEnum.OpenAccountClickBtn,
                                            });
                                        }}
                                    >
                                        <Translate langKey={LocalizationEnum.RegistrationFormTitle} />
                                    </Link>
                                )}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
