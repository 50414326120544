import * as Sentry from '@sentry/react';

import { UserType } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';

export const useUserTypeUpdate = (): OnEventType<UserType> => {
    const { setUserType } = useUserActionsSelector();

    return async (type: UserType) => {
        try {
            setUserType(type);
        } catch (error) {
            Sentry.captureException(error);
            throw new Error(error);
        }
    };
};
