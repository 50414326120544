import { MenuProps } from 'react-select';
import { List } from 'react-virtualized';

import { selectRowWrapper } from '../select-row-wrapper/select-row-wrapper';
import { RowRenderInterface } from '../select.props';

interface MenuListProps<T> {
    menuHeight: number;
    rowHeight: number;
    rowCount: number;
    rowRender: RowRenderInterface<T>;
    menuWidth: number;
}

export const SelectMenuList =
    <T,>({ menuWidth, menuHeight, rowHeight, rowCount, rowRender }: MenuListProps<T>) =>
        (props: MenuProps<T, boolean>) =>
            (
                <List
                    width={menuWidth}
                    height={menuHeight}
                    rowHeight={rowHeight}
                    rowCount={rowCount}
                    rowRenderer={selectRowWrapper(props, rowRender)}
                />
            );
