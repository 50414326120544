import { useMutation } from '@apollo/client';

import { apolloUploadClient } from '@connection/apollo-client';

import { File as FileUpload, UploadFile } from '../../graphql';
import { OnEventType } from '../../types/on-event.type';

const abortController = new AbortController();
export const useFileUpload = (commentId?: string): [OnEventType<File>, boolean, FileUpload | undefined, any, any] => {
    const [uploadFile, { loading: isLoading, data, error }] = useMutation(UploadFile, {
        client: apolloUploadClient,
    });
    const handleUploadFile = (file: File) => {
        uploadFile({
            variables: { file, commentId },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).catch(() => {});
    };
    const onCancel = () => {
        abortController.abort();
    };

    return [handleUploadFile, isLoading, data?.uploadFile, onCancel, error];
};
