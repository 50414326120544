import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';

export interface HeaderLinksInterface {
    link?: PageEnum;
    externalLink?: string;
    title: LocalizationEnum;
}

export const businessListLink: HeaderLinksInterface[] = [
    {
        link: PageEnum.Prices,
        title: LocalizationEnum.HeaderLinkPricing,
    },
    {
        link: PageEnum.ContactUs,
        title: LocalizationEnum.UserShortProfileLinkContactUs,
    },
];
export const listLink: HeaderLinksInterface[] = [
    {
        link: PageEnum.BusinessHome,
        title: LocalizationEnum.CommonForBusiness,
    },
    {
        link: PageEnum.Prices,
        title: LocalizationEnum.CommonTariffs,
    },
    {
        link: PageEnum.ContactUs,
        title: LocalizationEnum.UserShortProfileLinkContactUs,
    },
];
