import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useGoogleRecaptcha = (callback: any) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(
        async (props: any) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const verifyToken = await executeRecaptcha();
            callback(props, verifyToken);
        },
        [executeRecaptcha, callback]
    );

    return handleReCaptchaVerify;
};
