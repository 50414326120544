import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { SendPhoneCode } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { OnEventType } from '@shared-type/on-event.type';

export const useSendPhoneCode = (): [OnEventType<string>, boolean] => {
    const [sendPhoneCode, { data }] = useMutation(SendPhoneCode);
    const handleSendPhoneCode = useCallback(
        (phone: string, token: string) => sendPhoneCode({ variables: { phone, token } }),
        []
    );
    const handleReCaptchaVerify = useGoogleRecaptcha(handleSendPhoneCode);

    return [handleReCaptchaVerify, data?.sendPhoneCode.status ?? false];
};
