import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { Mutation, MutationUserStartPasswordResetArgs, UserStartPasswordReset } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

export const useForgotPassword = (): [OnEventType<MutationUserStartPasswordResetArgs>, string | null, boolean] => {
    const { onOpen } = useContext(InformationModalContext);

    const [sentMessage, setSentMessage] = useState<string | null>(null);
    const successMessage = useLocalizationText(LocalizationEnum.ForgotPasswordSentSuccessMessageTitle);

    const [userStartPasswordReset, { data, loading: isLoading, error }] =
        useMutation<Pick<Mutation, 'userStartPasswordReset'>>(UserStartPasswordReset);

    const userStartLoginHandler = useCallback((variables: MutationUserStartPasswordResetArgs, token: string) => {
        userStartPasswordReset({ variables: { ...variables, token } });
    }, []);

    const handleForgotPassword = useGoogleRecaptcha(userStartLoginHandler);

    useEffect(() => {
        if (isExist(data?.userStartPasswordReset.status) && isFalse(data?.userStartPasswordReset.status)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
        if (isTrue(data?.userStartPasswordReset.status)) {
            setSentMessage(successMessage);
        }
    }, [data]);

    useEffect(() => {
        if (isExist(error)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: error?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [error]);

    return [handleForgotPassword, sentMessage, isLoading];
};
