import { CurrenciesEnum } from '@shared-enum/currencies.enum';

export const MIN_START_YEAR = 1900;

export const TEXT_AREA_LENGTH = 3_000;

export const DEBOUNCE_DELAY = 500;
export const EXCHANGE_DEBOUNCE_DELAY = 1500;

export const OTHER_METHODS_BUTTON_VISIBILITY_DELAY = 20 * 1000;

export const REQUIRED_FIELD_ERROR = 'Complete this field';

export const GREAT_BRITAIN_COUNTRY_CODE = 'GB';
export const UKRAINE_COUNTRY_CODE = 'UA';

export const EMPTY_DATA = '-';

export const FRESHDESK_SUPPORT_NEW_TICKET_LINK = 'https://support.sends.co/support/tickets/new';
export const FRESHDESK_SUPPORT_HOME_LINK = 'https://support.sends.co/support/home';
export const SCHEDULE_DEMO_CALL_LINK = 'https://calendly.com/sendsco/sends-demo-call-for-business?primary_color=00997a';

export const DEFAULT_CURRENCY = CurrenciesEnum.GBP;
