import { observer } from 'mobx-react-lite';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { AuthPageContentWrapper, AuthPageFormTitle, AuthPageFormWrapper } from '@page/auth-pages/common.styles';
import { RegistrationPhoneForm } from '@page/registration/registration-phone/registration-phone-form/registration-phone-form';
import { useRegistrationPhoneForm } from '@page/registration/registration-phone/registration-phone-form/registration-phone-form.hook';
import { useUserSelector } from '@selector/user/use-user.selector';

interface RegistrationPhoneFromProps {
    isLoading?: boolean;
    onStartRegistration: OnEventType<string>;
}

const RegistrationPhone = observer(() => {
    const { isLoading = false, onStartRegistration }: RegistrationPhoneFromProps = useOutletContext();
    const [{ phone }] = useUserSelector();
    const { handlePhoneSubmit, handleBack } = useRegistrationPhoneForm(onStartRegistration);

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.RegistrationPhoneTitle} />
            </AuthPageFormTitle>
            <AuthPageFormWrapper>
                <AuthPageFormWrapper isLoading={isLoading}>
                    <RegistrationPhoneForm
                        onSubmit={handlePhoneSubmit}
                        onBack={handleBack}
                        isLoading={isLoading}
                        phone={phone}
                    />
                </AuthPageFormWrapper>
            </AuthPageFormWrapper>
        </AuthPageContentWrapper>
    );
});

export default RegistrationPhone;
