import { useMemo } from 'react';

import { Maybe, PaymentType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { AccountDetailsCardInterface } from '@component/modal/modals/account-details/account-details-card/account-details-card.interface';

interface AccountDetailsFieldsInterface {
    fields: { fieldName: LocalizationEnum; data: Maybe<string> }[];
    beforeListText: LocalizationEnum;
    afterListText?: LocalizationEnum;
}

interface PaymentTypeToFieldsMappingInterface {
    [key: string]: AccountDetailsFieldsInterface;
}

export const useAccountDetailsCard = ({ bank, selectedAccount }: AccountDetailsCardInterface) => {
    const { bankAddress, bankName, beneficiaryAddress, bic, iban, name, number, paymentType, sortCode } = useMemo(
        () => bank,
        [bank]
    );

    const paymentTypeToFieldsMapping: PaymentTypeToFieldsMappingInterface = useMemo(
        () => ({
            [PaymentType.local]: {
                fields: [
                    { fieldName: LocalizationEnum.CommonAccountName, data: selectedAccount.name ?? name },
                    { fieldName: LocalizationEnum.CreatePartnerFormBeneficiaryAddress, data: beneficiaryAddress ?? '' },
                    { fieldName: LocalizationEnum.PaymentFormCurrencyAmountStepAccountNumber, data: number },
                    { fieldName: LocalizationEnum.PaymentFormCurrencyAmountStepSortCode, data: sortCode },
                    { fieldName: LocalizationEnum.CommonBank, data: bankName },
                    { fieldName: LocalizationEnum.AccountDetailsCardBankAddress, data: bankAddress },
                ],
                beforeListText: LocalizationEnum.PleaseUseTheFollowingAccountDetailsToFundYourAccountInGbrFrom,
            },
            [PaymentType.sepa]: {
                fields: [
                    { fieldName: LocalizationEnum.CommonAccountName, data: selectedAccount.name ?? name },
                    { fieldName: LocalizationEnum.CreatePartnerFormBeneficiaryAddress, data: beneficiaryAddress ?? '' },
                    { fieldName: LocalizationEnum.AccountDetailsCardIban, data: iban },
                    { fieldName: LocalizationEnum.CommonSwiftBic, data: bic },
                    { fieldName: LocalizationEnum.CommonBank, data: bankName },
                    { fieldName: LocalizationEnum.AccountDetailsCardBankAddress, data: bankAddress },
                ],
                beforeListText: LocalizationEnum.PleaseUseTheFollowingAccountDetailsToFundYourAccountInEurFrom,
            },
            [PaymentType.swift]: {
                fields: [
                    { fieldName: LocalizationEnum.CommonAccountName, data: selectedAccount.name ?? name },
                    { fieldName: LocalizationEnum.CreatePartnerFormBeneficiaryAddress, data: beneficiaryAddress ?? '' },
                    { fieldName: LocalizationEnum.AccountDetailsCardIban, data: iban },
                    { fieldName: LocalizationEnum.CommonSwiftBic, data: bic },
                    { fieldName: LocalizationEnum.CommonBank, data: bankName },
                    { fieldName: LocalizationEnum.AccountDetailsCardBankAddress, data: bankAddress },
                    { fieldName: LocalizationEnum.IntermediarySwiftBic, data: 'BARCGB22' },
                ],
                beforeListText: LocalizationEnum.PleaseUseTheFollowingAccountDetailsToFundYourAccountInGbpEurAndUsd,
                afterListText: LocalizationEnum.IfYouCannotSpecifyTheIntermediaryBankThenPleaseIncludeTheIntermediary,
            },
        }),
        [bank, selectedAccount]
    );

    return useMemo(() => paymentTypeToFieldsMapping[paymentType], [paymentTypeToFieldsMapping, paymentType]);
};
