import { Translate } from '@shared-atom/translate/translate';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';
import { HeaderLeftSide } from '@page/main-pages/main-page-common/header/header-left-side/header-left-side';
import { HeaderRightSide } from '@page/main-pages/main-page-common/header/header-right-side/header-right-side';
import { PricingNotification, Wrapper } from '@page/main-pages/main-page-common/header/header.styles';
import { AlignContainer } from '@page/main-pages/main-page-common/main-page-common.styles';

export const Header = () => {
    const { name } = useCurrentNavigation();
    const navigate = useRouterNavigate();

    return (
        <Wrapper isDark={name === PageEnum.BusinessHome} shouldShowHeaderNotification={name === PageEnum.Home}>
            {name === PageEnum.Home && (
                <PricingNotification onClick={() => navigate(PageEnum.Prices)}>
                    <Translate langKey={LocalizationEnum.PricingUpdateForIndividuals} />
                </PricingNotification>
            )}
            <AlignContainer>
                <HeaderLeftSide isBusinessPage={name === PageEnum.BusinessHome} />
                <HeaderRightSide isBusinessPage={name === PageEnum.BusinessHome} />
            </AlignContainer>
        </Wrapper>
    );
};
