import styled, { css } from 'styled-components';

import CheckIcon from '../../assets/check.svg?react';
import { Typography } from '../../atoms/typography/typography';
import { Concrete, Dark, DarkGrey, Mystic, PrimeBlue, White } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import { ThemeTypeInterface } from '../../types/theme-props.type';
import { FieldErrorWrapper } from '../field-error/field-error.styles';

interface CheckBoxProps {
    isChecked: boolean;
    isDisabled: boolean;
}

interface CheckTitleProps {
    isExist: boolean;
}

const checkboxStyles = css`
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

export const CheckBoxStyle = styled.label<Pick<CheckBoxProps, 'isDisabled'>>`
    ${Flex.InlineFlex.Row.Style};
    min-height: 24px;
    user-select: none;

    ${({ isDisabled }) => !isDisabled && checkboxStyles}
`;

export const CheckBoxWrapper = styled.div`
    ${Flex.InlineFlex.Row.Style}
`;

const getBackgroundColor = (isChecked: boolean, isDisabled: boolean) => {
    if (isDisabled && !isChecked) {
        return Concrete;
    }

    return isChecked ? PrimeBlue : 'inherit';
};

export const CheckIconWrapper = styled.div<CheckBoxProps>`
    min-width: 18px;
    width: 18px;
    height: 18px;
    border-radius: 1px;
    box-shadow: ${({ isChecked, isDisabled }) => (isChecked || isDisabled ? 'none' : `0 0 0 2px ${Mystic}`)};
    background-color: ${({ isChecked, isDisabled }) => getBackgroundColor(isChecked, isDisabled)};
    ${Flex.VerticalCenter.HorizontalCenter.Style}

    opacity: ${({ isChecked, isDisabled }) => (isDisabled && isChecked ? '0.5' : null)};

    :hover {
        box-shadow: ${({ isChecked, isDisabled }) => (isChecked || isDisabled ? 'none' : `0 0 0 2px ${DarkGrey}`)};
        opacity: ${({ isChecked, isDisabled }) => (isChecked && !isDisabled ? 0.8 : null)};
    }
`;

export const Check = styled(CheckIcon)`
    width: 14px;
    height: 14px;
    fill: ${White};
`;

export const CheckTitle = styled.span<CheckTitleProps>`
    margin-left: 8px;
    white-space: ${({ isExist }) => (isExist ? 'nowrap' : 'inherit')};
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.Style};
    @media screen and (max-device-width: 480px) {
        white-space: ${({ isExist }) => (isExist ? 'normal' : 'inherit')};
    }
`;

export const ErrorWrapper = styled(FieldErrorWrapper)``;
